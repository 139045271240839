import { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LanguageEnum } from '../../../config/i18n';
import {
  getAuthToken,
  RECAPTCHA,
  removeAuthToken,
  SAVED_PAYMENT_EMAIL,
  setAuthToken,
  setRefreshToken,
} from '../../../helpers/authHeader';
import { showMessage } from '../../../helpers/notifications';
import { CAPTCHA_SITE_KEY_V2, SBP_BANK } from '../../../helpers/settings';
import { classNames } from '../../../helpers/utils';
import { useNotifications } from '../../../hooks/useChat';
import { api, updater } from '../../../utils/api';
import { FetchError, isJson } from '../../../utils/fetchApi';
import { getItem, setItem } from '../../../utils/localStorage';
import { Autodeal, Broker, Deal, Merchant, Purchase } from '../../../utils/types';
import { RestartPanel } from '../../blocks/RestartPanel';
import { isTemporaryUser, UNEXPECTED_ERROR } from '../../common';
import { searchBidMaxMinutes } from '../../common/constants';
import { TelegramIcon, YouTubeIcon } from '../../common/Icons';
import {
  VIDEO_HINTS_SRC_BY_CURRENCY,
  VIDEO_HINTS_SRC_BY_LANG,
} from '../../common/Landing';
import { LanguageSelector } from '../../common/languageSelector/LanguageSelector';
import { PaymentInit } from './init';
import { PaymentMain } from './payment';
import { PaymentProcessed } from './processed';
import styles from './styles.module.scss';

type PaymentStep = 'init' | 'payment' | 'processed' | 'restart';

const mainDealStates = ['paid', 'closed', 'deleted'];

interface PaymentParams {
  id: string;
}

export enum PURCHASE_STATUSES {
  CREATED,
  IN_PROCESS,
  OK,
  NOT_OK,
}

const getStep = (purchase: Purchase, brokerId?: string) => {
  const initialized =
    !purchase || (purchase.status === PURCHASE_STATUSES.CREATED && !brokerId);
  const loadingData =
    purchase?.status === PURCHASE_STATUSES.IN_PROCESS &&
    !brokerId &&
    !purchase?.deal &&
    !purchase.autodeal;

  if (initialized || loadingData) {
    return 'init';
  }

  const inProcess = purchase.status === PURCHASE_STATUSES.IN_PROCESS;
  const dealRequisitesWaiting = !(
    mainDealStates.includes(purchase.deal?.state) || purchase.deal?.dispute
  );
  const autodealRequisitesWaiting = !mainDealStates.includes(purchase.autodeal?.state);
  const requisitesStage = inProcess && dealRequisitesWaiting;
  const waitingAutodeal = inProcess && autodealRequisitesWaiting;
  const createdOrProcessing = [
    PURCHASE_STATUSES.IN_PROCESS,
    PURCHASE_STATUSES.CREATED,
  ].includes(purchase?.status);
  const waitingForRequisites = createdOrProcessing && !purchase?.deal && !!brokerId;
  const waitingForRequisitesAutodeal =
    createdOrProcessing && purchase?.autodeal && !!brokerId && !purchase.requisites;

  if (
    purchase.autodeal
      ? waitingAutodeal || waitingForRequisitesAutodeal
      : requisitesStage || waitingForRequisites
  ) {
    return 'payment';
  }

  const inProcessOrEnded = purchase.status >= PURCHASE_STATUSES.IN_PROCESS;
  const inMainDealStates =
    mainDealStates.includes(purchase.deal?.state) || purchase.deal?.dispute;
  const inMainAutodealStates = mainDealStates.includes(purchase.autodeal?.state);
  const isCancelled = purchase.cancel_reason;

  if ((inProcessOrEnded && inMainDealStates) || inMainAutodealStates || isCancelled) {
    return 'processed';
  }
};

let stopWatchPayment = () => void 0;

export const IS_DEFAULT_NOTIFICATION_READ = 'IS_DEFAULT_NOTIFICATION_READ';

const PurchasePage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<PaymentParams>();

  const [deal, setDeal] = useState<Deal>();
  const [autodeal, setAutodeal] = useState<Autodeal>();
  const [dealId, setDealId] = useState<string>();
  const [purchase, setPurchase] = useState<Purchase>();

  const [amountCurrency, setAmountCurrency] = useState<number>();
  const [amountCrypto, setAmountCrypto] = useState<number>();

  const [mask, setMask] = useState<string>('');
  const [broker, setBroker] = useState<Broker>();
  const [brokerId, setBrokerId] = useState<string>();
  const [brokers, setBrokers] = useState<Broker[]>([]);

  const [bannedBids, setBannedBids] = useState<string[]>([]);
  const [recaptcha, setRecaptcha] = useState<string>('');
  const [hintContent, setHintContent] = useState<string>();

  const [merchant, setMerchant] = useState<Merchant>();

  const [showRestart, setShowRestart] = useState(false);
  const [findBidStartDate, setFindBidStartDate] = useState<Date>();

  const recaptchaRef = useRef<any>(null);

  useEffect(() => {
    purchase?.merchant_id &&
      api.purchases
        .getMerchant(Number(purchase.merchant_id))
        .then(setMerchant)
        .catch(() => undefined);
  }, [purchase?.merchant_id]);

  useEffect(() => {
    setBrokerId(broker?.id);
    if (broker?.id) {
      !purchase?.deal && !purchase?.autodeal && startPayment();
    }
  }, [broker?.id, !!purchase?.deal, !!purchase?.autodeal]);

  useEffect(() => {
    if (purchase) {
      getBrokers();
    }
  }, [purchase, amountCrypto, amountCurrency]);

  useEffect(() => {
    if (purchase && !purchase.page_opened_at) {
      api.purchases.postPageOpened(purchase.payment_id);
    }
  }, [purchase?.page_opened_at]);

  useEffect(() => {
    deal?.id && setDealId(deal.id);
  }, [deal?.id]);

  const step = useMemo(() => getStep(purchase, broker?.id), [purchase, broker?.id]);

  const onSetBroker = (broker: Broker) => {
    !purchase.broker_id && setBroker(broker);
  };

  useEffect(() => {
    const stopWatchPaymentStatus = updater(getPurchaseInfo);
    stopWatchPayment = stopWatchPaymentStatus;
    return () => stopWatchPaymentStatus();
  }, []);

  useEffect(() => {
    bannedBids.length && startPayment();
  }, [bannedBids.length]);

  const startPayment = (restarted?: boolean) => {
    const start = () => {
      api.purchases
        .findAndStartBid(
          purchase.symbol.toLowerCase(),
          bannedBids,
          id,
          amountCrypto,
          amountCurrency,
          broker.id,
          purchase.address,
          purchase.currency.toLowerCase(),
        )
        .then((deal) => {
          if (deal.deal) {
            setDeal(deal.deal);
            getPurchaseInfo();
          } else if (deal.autodeal) {
            setAutodeal(deal.autodeal);
            getPurchaseInfo();
          } else {
            setShowRestart(true);
          }
        })
        .catch(handleUnauthorized);
    };

    if (!findBidStartDate || restarted) {
      setShowRestart(false);
      setFindBidStartDate(new Date());
    } else if (
      new Date().getTime() - findBidStartDate.getTime() >
      searchBidMaxMinutes * 60 * 1000
    ) {
      setFindBidStartDate(undefined);
      setShowRestart(true);
      return;
    }

    const token = getAuthToken();
    token ? start() : registerTemporaryUser(start);
  };

  const showCaptcha = async () => {
    if (recaptcha) return recaptcha;
    if (recaptchaRef.current) {
      try {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        setRecaptcha(captchaToken);
        setItem(RECAPTCHA, captchaToken);
        return captchaToken;
      } catch (error) {
        return showCaptcha();
      }
    }
  };

  const registerTemporaryUser = async (cb: () => void) => {
    const savedEmail = getItem(SAVED_PAYMENT_EMAIL);
    const captcha = recaptcha || (await showCaptcha());
    captcha
      ? api.auth
          .registerTemporary(savedEmail || undefined, captcha, true)
          .then(({ access, refresh, email }) => {
            if (access) {
              if (!savedEmail && email) {
                setItem(SAVED_PAYMENT_EMAIL, email);
              }
              setAuthToken(access);
              refresh && setRefreshToken(refresh);
              if (isTemporaryUser(access)) {
                api.purchases
                  .setToken(id, access)
                  .then(() => cb())
                  .catch(() => undefined);
              }
            } else {
              alert(UNEXPECTED_ERROR);
            }
          })
          .catch((e) => {
            if (e.response) {
              if (isJson(e.response)) {
                e.response.json().then((result) => {
                  showMessage('error', result.detail || e.response.statusText);
                });
              } else {
                showMessage('error', e.response.statusText);
              }
            }
          })
      : registerTemporaryUser(cb);
  };

  const getBrokers = () => {
    if (purchase && purchase.deal) return;
    if (broker) return;

    api.purchases
      .getBrokers(
        purchase?.currency.toLowerCase(),
        'sky-pay',
        purchase?.symbol.toLowerCase(),
        undefined,
        id,
        amountCrypto,
        amountCurrency,
      )
      .then((brokers) => {
        setBrokers(brokers);
        if (purchase.broker_id) {
          setBroker(brokers.find((broker) => broker.id === purchase.broker_id));
        }
      })
      .catch(() => undefined);
  };

  const getPurchaseInfo = (cb?: () => void) =>
    api.purchases
      .get(id)
      .then((purchase) => {
        if (purchase.token) {
          setAuthToken(purchase.token);
        }

        setPurchase(purchase);

        setAmountCrypto(!purchase.is_currency_amount ? purchase.amount : undefined);
        setAmountCurrency(purchase.is_currency_amount ? purchase.amount : undefined);

        setDeal(purchase.deal);
        setAutodeal(purchase.autodeal);

        !broker && purchase.deal && setBroker(purchase.deal.lot.broker);
        !broker && purchase.autodeal && setBroker(purchase.autodeal.broker);
      })
      .then(cb)
      .catch(handleUnauthorized);

  const onDealNotification = () => {
    getPurchaseInfo();
  };

  const onNotification = (notification) => {
    if (notification.type === 'deal') {
      getPurchaseInfo();
    }
    if (['cancel_deal', 'timeout'].includes(notification.type)) {
      api.deal.get(notification.details.id).then((updatedDeal) => {
        if (updatedDeal.state === 'deleted') {
          if (updatedDeal?.lot.id) {
            setBannedBids((prev) => [...prev, updatedDeal.lot.id]);
          }
        }
      });
    }
  };

  const notifications = useNotifications({
    deal: deal,
    autodeal: autodeal,
    type: 'purchase',
    defaultTab: 'info',
    onDealNotification: onDealNotification,
    handlers: { notification: (notification) => onNotification(notification) },
    showCaptcha,
  });

  useEffect(() => {
    notifications.WSConnected && stopWatchPayment();
  }, [notifications.WSConnected]);

  const handleUnauthorized = (e: FetchError) => {
    if ((e.response && e.response.status === 401) || e.message === 'too many attempts') {
      removeAuthToken();
    }
  };

  const onConfirmPayment = async (mask?: string) => {
    if (mask) {
      await api.deal.runWithMask(deal?.id ?? autodeal?.identificator, mask);
      await api.deal.run(deal?.id ?? autodeal?.identificator);
    } else {
      await api[deal?.id ? 'deal' : 'autodeal'].run(deal?.id ?? autodeal?.identificator);
    }
    await getPurchaseInfo();
    return Promise.resolve();
  };

  const onCancelPayment = (end?: () => void) => {
    const postprocess = (promise: Promise<any>) =>
      promise
        .then(() => getPurchaseInfo(() => setBroker(null)))
        .catch(() => undefined)
        .finally(() => {
          end?.();
        });

    if (!!purchase.deal?.id && purchase.deal?.state !== 'deleted') {
      postprocess(api.deal.cancel(purchase.deal.id));
    }
    if (purchase.autodeal?.identificator) {
      postprocess(api.autodeal.cancel(purchase.autodeal.identificator));
    }
  };

  const restartPayment = () => {
    setBroker(undefined);
    setShowRestart(false);
    setFindBidStartDate(undefined);
    getPurchaseInfo();
  };

  const restartPaymentAlt = () => {
    setBroker(brokers.find((broker) => broker.id === SBP_BANK));
    setFindBidStartDate(undefined);
    getPurchaseInfo();
  };

  const steps: Record<PaymentStep, JSX.Element> = {
    init: (
      <PaymentInit brokers={brokers} purchase={purchase} onBrokerSelect={onSetBroker} />
    ),
    restart: (
      <RestartPanel
        pm={broker}
        deal={purchase}
        onRestart={restartPayment}
        onRestartAlt={restartPaymentAlt}
        type={'purchase'}
        onCancel={() => {
          startPayment(true);
        }}
      />
    ),
    payment: (
      <PaymentMain
        purchase={purchase}
        merchant={merchant}
        broker={broker}
        onConfirmPayment={onConfirmPayment}
        onCancelDeal={onCancelPayment}
        notifications={notifications}
      />
    ),
    processed: <PaymentProcessed notifications={notifications} purchase={purchase} />,
  };

  const dealFooter = (() => {
    if (
      ([0, 1].includes(purchase?.status) &&
        !['paid', 'closed', 'deleted'].includes(purchase?.deal?.state)) ||
      (purchase?.deal?.state === 'confirmed' && purchase?.deal?.requisite)
    ) {
      return (
        <div className={styles.footer}>
          <a
            target="_blank"
            className={styles.block}
            href={`https://www.youtube.com/embed/${
              ['azn'].includes(purchase?.currency)
                ? VIDEO_HINTS_SRC_BY_CURRENCY.purchase[purchase.currency.toUpperCase()]
                : VIDEO_HINTS_SRC_BY_LANG.purchase[i18n.language]
            }`}
            rel="noreferrer">
            <YouTubeIcon
              color={'#0000003D'}
              className={classNames(styles.icon, styles.youtube)}
            />
            <span>{`${t('purchase.how-to-pay')}`}</span>
          </a>
          <a href="https://t.me/sky_pay_support" className={styles.block}>
            <TelegramIcon
              color={'#0000003D'}
              className={classNames(styles.icon, styles.tg)}
            />
            <span>{`${t('flash-pay.support')}`}</span>
          </a>
        </div>
      );
    }
  })();

  // const hints = [1, 2, 3].map((hintIndex) => {
  //   return (
  //     <div
  //       className={styles.hintTitle}
  //       onClick={() => setHintContent(`purchase.hints.${hintIndex}.description`)}>{`${t(
  //       `purchase.hints.${hintIndex}.caption`,
  //     )}`}</div>
  //   );
  // });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={'/ico/logos/sky_pay.svg'} alt={'logo'} className={styles.logo} />
        <div className={styles.title}>{t('purchase.title').toUpperCase()}</div>
        <div className={styles.lang}>
          <LanguageSelector noBorder lang={purchase?.lang ?? LanguageEnum.RU} />
        </div>
      </div>

      <div className={styles.stepsContainer}>
        {purchase && showRestart ? steps['restart'] : steps[step]}
        {/* {step === 'init' ? steps[step] : steps['restart']} */}
        {dealFooter}
      </div>

      {/* <div className={styles.hints}>{hints}</div>
      <Confirm open={!!hintContent} noActions onCancel={() => setHintContent('')}>{`${t(
        hintContent,
      )}`}</Confirm> */}

      <div>
        <form>
          <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY_V2} size="invisible" ref={recaptchaRef} />
        </form>
      </div>
    </div>
  );
};

export { PurchasePage };
