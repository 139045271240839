import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Broker, Sale } from '../../../../utils/types';
import IconEx from '../../../controls/IconEx';
import { paymentMethodsOrder } from '../../../paymentMethods';
import { LoadingWrapper } from '../../../universalComponents/LoadingWrapper';
import { OrderDetails } from '../OrderDetails';
import styles from './styles.module.scss';

type ISaleInitProps = {
  onBrokerSelect: (broker: Broker) => void;
  brokers: Broker[];
  sale?: Sale;
};

export const SaleInit: FC<ISaleInitProps> = ({ brokers, onBrokerSelect, sale }) => {
  const { t } = useTranslation();

  const [currentBroker, setCurrentBroker] = useState<Broker>();

  useEffect(() => {
    currentBroker?.id && onBrokerSelect(currentBroker);
  }, [currentBroker?.id]);

  const setBroker = (pm: Broker) => () => {
    setCurrentBroker(pm);
  };

  return (
    <div className={styles.container}>
      <LoadingWrapper isLoading={!brokers?.length || !!currentBroker}>
        <OrderDetails sale={sale} />
        <span className={styles.headerText}>{`${t('sale.start.payment-method')}`}:</span>
        <div className={styles.brokers}>
          {(brokers.length &&
            [
              ...brokers
                .filter((broker) => paymentMethodsOrder.includes(broker.name))
                .sort(
                  (a, b) =>
                    paymentMethodsOrder.indexOf(a.name) -
                    paymentMethodsOrder.indexOf(b.name),
                ),
              ...brokers.filter((broker) => !paymentMethodsOrder.includes(broker.name)),
            ]
              .filter((pm) => pm.is_available)
              .filter((pm) => {
                const dealDefBrokerId = sale?.broker_id ? pm.id === sale.broker_id : true;
                return dealDefBrokerId && ('is_available' in pm ? pm.is_available : true);
              })
              .map((pm, n) => {
                return (
                  <div className={styles.broker} key={n} onClick={setBroker(pm)}>
                    {pm.logo && <IconEx src={pm.logo} size={'5vh'} alt={'logo'} />}
                    <span>{pm.name}</span>
                  </div>
                );
              })) ||
            undefined}
        </div>
      </LoadingWrapper>
    </div>
  );
};
