import { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { notify } from '../../helpers/utils';
import { ACCEPTED_IMAGES_MIME_TYPES } from '../common';
import { AttachBox, AttachmentButton } from './Chat';

const MAX_FILE_SIZE = 2.5 * 1024 * 1024;

export const AttachmentBox = ({
  size,
  iconElement,
  onChange,
  disabled,
  className,
}: {
  size?: string;
  iconElement?: ReactElement;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation();

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files[0]?.size >= MAX_FILE_SIZE) {
      notify(t('errors.Payload is too large'), 'error');
      e.target.value = '';
      return;
    }

    onChange(e);
  };

  return (
    <AttachBox className={className}>
      {iconElement ?? (
        <AttachmentButton size={size ?? '1.5rem'} hint={t('chat.attachments-hint')} />
      )}
      <input
        type={'file'}
        disabled={disabled}
        autoComplete={'off'}
        accept={ACCEPTED_IMAGES_MIME_TYPES}
        onChange={handleFile}
      />
    </AttachBox>
  );
};
