import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_translation from '../translations/en.json';
import ru_translation from '../translations/ru.json';
import tj_translation from '../translations/tj.json';
import ua_translation from '../translations/ua.json';
import uz_translation from '../translations/uz.json';

export enum LanguageEnum {
  EN = 'en',
  RU = 'ru',
  UA = 'ua',
  UZ = 'uz',
  TJ = 'tj',
}

const resources = {
  en: en_translation,
  ru: ru_translation,
  ua: ua_translation,
  uz: uz_translation,
  tj: tj_translation,
};

const DEFAULT_LANG = LanguageEnum.RU;
export const LANG_FIELD = 'lang';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem(LANG_FIELD) || DEFAULT_LANG,
    fallbackLng: DEFAULT_LANG,

    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export function getLanguage(): string {
  return i18n.language;
}

export function setLanguage(lang: string, dispatchEvent = true) {
  i18n.changeLanguage(lang).then(() => localStorage.setItem(LANG_FIELD, lang));
  dispatchEvent && window.dispatchEvent(new Event('storage'));
}

export function onLanguageChange(callback: (lang: string) => void) {
  i18n.on('languageChanged', callback);
}

export function t(key?: string, def?: string): string {
  return key ? i18n.t(key, def) : def || '';
}

export function tt(prefix: string, key?: string, def?: string): string {
  return key ? i18n.t(`${prefix}.${key}`, def) : def || '';
}
