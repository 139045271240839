import { Dialog, DialogActions } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { CloseButton, Hr, px, Spacer, TextEx } from '../common';
import ActionButton from './ActionButton';
import Txt from './Txt';

export interface DialogColors {
  submitColor?: string;
  submitHover?: string;
}

export const Confirm = ({
  open,
  submitCaption,
  cancelCaption,
  children,
  disabledSubmit = false,
  loadingSubmit = false,
  onSubmit,
  noActions,
  onCancel,
  colors,
}: {
  open: boolean;
  submitCaption?;
  cancelCaption?: string;
  children: React.ReactNode;
  colors?: DialogColors;
  disabledSubmit?: boolean;
  noActions?: boolean;
  loadingSubmit?: boolean;
  onSubmit?;
  onCancel: () => void;
}) => (
  <Dialog open={open} onClose={onCancel} maxWidth={false}>
    <CloseButton position={'absolute'} right={'1.2vh'} top={'1.2vh'} onClick={onCancel} />
    <DialogContent top={'3vh'}>{children}</DialogContent>
    {!noActions && (
      <DialogActions style={{ padding: '20px', justifyContent: 'center' }}>
        <ActionButton
          disabled={disabledSubmit}
          loading={loadingSubmit}
          width={'clamp(130px, 20vw, 250px)'}
          caption={submitCaption || 'common.confirm'}
          onClick={onSubmit}
          buttonColor={colors ? colors.submitColor : undefined}
          hoverColor={colors ? colors.submitHover : undefined}
        />
        <Spacer width={'1vw'} />
        <ActionButton
          caption={cancelCaption || 'common.cancel'}
          width={'clamp(130px, 20vw, 250px)'}
          onClick={onCancel}
          buttonColor={'#fd3d34'}
          hoverColor={'#fd3d34'}
          swapColors
        />
      </DialogActions>
    )}
  </Dialog>
);

export const MessageBox = ({
  open,
  children,
  caption,
  closeCaption,
  onClose,
  cancelCaption,
  onCancel,
  onCloseBtnClick,
}: {
  open: boolean;
  children: React.ReactNode;
  caption?: string;
  closeCaption?: string;
  onClose: () => void;
  cancelCaption?: string;
  onCancel?: () => void;
  onCloseBtnClick?: () => void;
}) => (
  <Dialog open={open} onClose={onCloseBtnClick || onCancel || onClose} maxWidth={false}>
    {caption ? (
      <>
        <MessageBoxCaption>
          <TextEx>
            <Txt k={caption} />
          </TextEx>
        </MessageBoxCaption>
        <Hr />
      </>
    ) : undefined}
    <CloseButton
      position={'absolute'}
      right={'1.2vh'}
      top={'1.2vh'}
      onClick={onCloseBtnClick || onCancel || onClose}
    />
    <DialogContent top={!caption ? '3vh' : undefined}>{children}</DialogContent>
    {caption ? <Hr /> : undefined}
    <DialogActions style={{ padding: '2vh', justifyContent: 'center' }}>
      <ActionButton
        caption={closeCaption || 'common.close'}
        minWidth={'14vh'}
        onClick={onClose}
      />
      {onCancel ? (
        <>
          <Spacer width={'0.6vh'} />
          <ActionButton
            caption={cancelCaption || 'common.cancel'}
            onClick={onCancel}
            buttonColor={'#fd3d34'}
            hoverColor={'#fd3d34'}
            swapColors
          />
        </>
      ) : undefined}
    </DialogActions>
  </Dialog>
);

export const DialogTextLine = (props) => (
  <DialogTextLineRoot height={props.lineHeight} top={props.top}>
    <TextEx {...props}>{props.children}</TextEx>
  </DialogTextLineRoot>
);

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => px(props.top)};
  padding: 20px 20px 0 20px;
`;
const DialogTextLineRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => px(props.height)};
  width: 100%;
  margin-top: ${(props) => px(props.top)};
`;
const MessageBoxCaption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 4vh;
  height: 6vh;
`;
