import React from 'react';
import styled from 'styled-components';

import { getRequiredLink } from '../../../helpers/settings';
import { urlToOpen } from '../../../helpers/utils';
import { Defaults, LinkEx, px, TextEx } from '../../common';
import Txt from '../../controls/Txt';
import { NotFoundIcon } from '../../PageNotFound';

export default class PagePaymentError extends React.Component {
  public render(): React.ReactNode {
    const redirectUrl = getRequiredLink();

    return (
      <NotFoundRoot>
        <div className={'content'}>
          <NotFoundIcon size={'15vh'} fill={Defaults.mainColor} />
          <TextEx size={'2.4vh'} top={'3vh'} uppercase>
            <Txt k={'error-page.payment.caption'} />
          </TextEx>
          <TextEx
            size={'1.6vh'}
            top={'3vh'}
            textAlign={'center'}
            width={'70vh'}
            lineHeight={'3vh'}>
            <Txt k={'error-page.payment.description'} />
          </TextEx>
          <LinkEx
            size={'1.6vh'}
            top={'1vh'}
            onClick={() => (window.location.href = urlToOpen(redirectUrl))}>
            {redirectUrl}
          </LinkEx>
        </div>
      </NotFoundRoot>
    );
  }
}

const NotFoundRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;

  & .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh;
    background-color: white;
    border-radius: ${px(Defaults.borderRadius)};
  }
`;
