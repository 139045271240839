import React from 'react';
import styled from 'styled-components';

import { px, TextEx } from './common';
import { BankType } from './common/types';
import IconEx from './controls/IconEx';
import { pageFontSize } from './controls/Page';

export const paymentMethodsOrder = [
  'СБП (банк-банк)',
  'МИР',
  'Любой банк РФ',
  'Сбербанк',
  'Тинькофф',
  'QIWI',
  'ЮMoney',
  'Пополнение телефона',
  'Райффайзен',
  'Альфа-Банк',
  'Росбанк',
  'Ozon Банк',
  'Газпромбанк',
  'МТС-банк',
  'Ак Барс банк',
  'Почта банк',
  'Совкомбанк',
  'Промсвязьбанк',
  'Открытие',
  'Авангард',
  'Уральский банк',
  'Русский Стандарт',
  'MasterCard',
  'VISA',
];

export const PaymentMethodBox = ({
  paymentMethod,
  fontSize = pageFontSize,
  imgSize,
  left,
}: {
  paymentMethod: BankType;
  left?;
  fontSize?;
  imgSize?: number | string;
}) => {
  const { name, logo } = paymentMethod;

  return (
    <PaymentMethodBoxRoot left={left}>
      {logo && <IconEx src={logo} size={imgSize || fontSize} />}
      <TextEx left={'0.8vh'} size={fontSize}>
        {name || ''}
      </TextEx>
    </PaymentMethodBoxRoot>
  );
};

const PaymentMethodBoxRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
`;
