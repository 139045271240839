import React, { ReactElement } from 'react';
import { SpinnerCircular } from 'spinners-react';

import { Defaults } from '../../common';
import styles from './styles.module.css';

interface LoadingWrapperProps {
  isLoading: boolean;
  children: any;
  description?: string | ReactElement;
}

export const LoadingWrapper = ({
  isLoading,
  children,
  description,
}: LoadingWrapperProps) => {
  return (
    <>
      {isLoading ? (
        <>
          <SpinnerCircular
            className={styles.spinner}
            color={Defaults.mainColor}
            secondaryColor={Defaults.grayColor}
            style={{
              width: '100%',
              height: '30vh',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
          {description && <span className={styles.description}>{description}</span>}
        </>
      ) : (
        children
      )}
    </>
  );
};
