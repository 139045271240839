import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LanguageEnum } from '../../../config/i18n';
import { api, updater } from '../../../utils/api';
import {
  Broker,
  FlashPayBroker,
  Merchant,
  PurchaseV2,
  SaleV2,
} from '../../../utils/types';
import { FlashPayLogo, TelegramIcon } from '../../common/Icons';
import { LanguageSelector } from '../../common/languageSelector/LanguageSelector';
import { FlashPayInit } from './init';
import { FlashPayPayment } from './payment';
import { FlashPayProcessed } from './processed';
import styles from './styles.module.css';

export type FlashPayType = 'payment' | 'sale';

type FlashPayStep = 'init' | 'payment' | 'processed';

interface FlashPayParams {
  id: string;
  type: FlashPayType;
}

enum STATUSES {
  CREATED,
  IN_PROCESS,
  OK,
  NOT_OK,
}

const STATUSES_MAP = {
  [STATUSES.CREATED]: 'init',
  [STATUSES.IN_PROCESS]: 'payment',
  [STATUSES.OK]: 'processed',
  [STATUSES.NOT_OK]: 'processed',
};

export const FlashPay = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<FlashPayStep>('init');
  const [deal, setDeal] = useState<PurchaseV2 | SaleV2>();

  const [broker, setBroker] = useState<FlashPayBroker>();
  const [brokers, setBrokers] = useState<FlashPayBroker[]>();

  const [merchant, setMerchant] = useState<Merchant>();

  const { id, type } = useParams<FlashPayParams>();

  useEffect(() => {
    deal?.merchant_id &&
      api.purchases
        .getMerchant(Number(deal.merchant_id))
        .then(setMerchant)
        .catch(() => undefined);
  }, [deal?.merchant_id]);

  useEffect(() => {
    if (['init', 'payment'].includes(step)) {
      if (deal?.status) setStep(STATUSES_MAP[deal.status]);
    }
  }, [step, deal]);

  useEffect(() => {
    const stopWatchPaymentStatus = updater(getDealInfo);
    return () => stopWatchPaymentStatus();
  }, []);

  const onSetBroker = (broker: FlashPayBroker, mask?: string) => {
    api.flashpay
      .patch({ id: deal.id, brokerId: broker.broker_id, mask })
      .then(getDealInfo);
  };

  const getBrokers = (deal: PurchaseV2 | SaleV2) => {
    if (broker) return;

    api.purchases
      .getFlashPayBrokers(deal?.['currency']?.toLowerCase() ?? 'rub')
      .then((brokers) => {
        setBrokers(brokers);
        if (deal.broker_id) {
          setBroker(brokers.find((broker) => broker.broker_id === deal.broker_id));
          setStep('payment');
        }
      })
      .catch(() => undefined);
  };

  const getDealInfo = () => {
    const requests = {
      payment: api.flashpay.getPurchase,
      sale: api.flashpay.getSale,
    };
    requests[type](id).then((deal) => {
      setDeal(deal);
      getBrokers(deal);
    });
  };

  const steps: Record<FlashPayStep, JSX.Element> = {
    init: (
      <FlashPayInit
        type={type}
        brokers={brokers}
        merchant={merchant}
        deal={deal}
        onBrokerSelect={onSetBroker}
      />
    ),
    payment: (
      <FlashPayPayment
        deal={deal}
        type={type}
        requisites={deal?.requisites}
        id={deal?.id}
        broker={broker}
      />
    ),
    processed: (
      <FlashPayProcessed
        type={type}
        id={deal?.id}
        deal={deal}
        isOk={deal?.status === STATUSES.OK}
      />
    ),
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <FlashPayLogo className={styles.logo} />

          <div className={styles.lang}>
            <LanguageSelector lang={deal?.lang ?? LanguageEnum.RU} />
          </div>
        </div>

        {deal && steps[step]}

        <div className={styles.footer}>
          {/* <a
            target="_blank"
            className={styles.footerBlock}
            href={`https://www.youtube.com/embed/${
              VIDEO_HINTS_SRC_BY_CURRENCY[type === 'payment' ? 'purchase' : 'sale'][
                deal?.['currency']?.toUpperCase() ?? 'RUB'
              ]
            }`}
            rel="noreferrer">
            <YouTubeIcon color={'#0000003D'} className={styles.footerIcon} />
            {`${t('flash-pay.instruction')}`}
          </a> */}
          <a href="tg://resolve?domain=SKY_CRYPTO_SUPPORT" className={styles.footerBlock}>
            <TelegramIcon color={'#0000003D'} className={styles.footerIcon} />
            {`${t('flash-pay.support')}`}
          </a>
        </div>
      </div>
    </div>
  );
};
