import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames, formatNumber, urlToOpen } from '../../../../helpers/utils';
import { INotificationsAggregator } from '../../../../hooks/useChat';
import { api } from '../../../../utils/api';
import { Sale } from '../../../../utils/types';
import { Defaults, HtmlText, RoundBox, Row, Timer } from '../../../common';
import { actionHoverColor, labelColor, supportUser } from '../../../common/constants';
import {
  BigNotOkIcon,
  BigOkIcon,
  DisputeIcon,
  InfoIcon,
  LikeIcon,
  ToMessageIcon,
} from '../../../common/Icons';
import { DealAction } from '../../../common/panels/controls';
import { TabsComponent } from '../../../common/tabs';
import ActionButton from '../../../controls/ActionButton';
import ChatEx from '../../../controls/Chat';
import { Confirm } from '../../../controls/Dialog';
import { pageFontSize, PageText } from '../../../controls/Page';
import { OrderDetails } from '../OrderDetails';
import { SALE_STATUSES } from '../Sale';
import styles from './styles.module.scss';

interface ISaleProcessedProps {
  sale?: Sale;
  notifications: INotificationsAggregator;
  onSendCoins: () => Promise<void>;
}

export const SaleProcessed: FC<ISaleProcessedProps> = ({
  sale,
  notifications,
  onSendCoins,
}) => {
  const { t } = useTranslation();
  const [voted, setVoted] = useState(false);
  const [showConfirmSendCoins, setShowConfirmSendCoins] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    sale.deal?.id && getVoted();
  }, [sale.deal?.id]);

  const showSendCoinsModal = () => setShowConfirmSendCoins(true);

  const sendCoins = async () => {
    setBusy(true);
    setShowConfirmSendCoins(false);
    await onSendCoins();
    setBusy(false);
  };

  const onUserVote = (method: 'like' | 'dislike') => {
    if (sale.deal)
      api.deal.voteUser(sale.deal?.id, sale.deal?.buyer.nickname, method).then(getVoted);
  };

  const getVoted = () => {
    api.deal.get(sale.deal.id).then((deal) => setVoted(deal.voted));
  };

  const status = useMemo(
    () => ({
      ok: sale.status === SALE_STATUSES.OK,
      notOk: sale.status === SALE_STATUSES.NOT_OK,
      waiting:
        sale.status === SALE_STATUSES.IN_PROCESS &&
        ['paid', 'confirmed'].includes(sale.deal?.state) &&
        !sale.deal?.dispute,
    }),
    [sale.status, sale.deal?.state, !sale.deal?.dispute],
  );

  const backToSiteButton = useMemo(() => {
    if (!sale?.deal?.dispute && [2, 3].includes(sale?.status)) {
      return (
        <div className={styles.footer}>
          <DealAction
            className={styles.action}
            caption={'purchase.redirect'}
            onClick={() =>
              (window.location.href = urlToOpen(
                sale.back_url || sale.merchant.website || '',
              ))
            }
            width={'300px'}
          />
        </div>
      );
    }
    return;
  }, [sale]);

  const isDispute = useMemo(() => !!sale.deal?.dispute, [sale.deal?.dispute]);

  const waitingBlock = status.waiting && (
    <div className={classNames(styles.infoContainer, styles.waiting)}>
      <Timer
        time={sale.deal.expire_in * 2}
        isNew
        vertical
        color={'#00B3FB'}
        clockSize={'80px'}
      />
      <div className={styles.title}>
        {sale.deal.state !== 'paid'
          ? `${t('sale.deal.status.active')}`
          : `${t('sale.deal.status.paid')}`}
      </div>
      {sale.deal.state !== 'paid' && (
        <>
          <HtmlText
            className={styles.tip}
            k={'sale.deal.wait-payment-1'}
            textAlign={'center'}
            args={[sale.deal?.buyer.nickname]}
          />
        </>
      )}
      {sale.deal.state === 'paid' && (
        <>
          <HtmlText
            className={styles.tip}
            k={'sale.deal.send-coins-1'}
            size={'2vh'}
            textAlign={'center'}
            args={[
              formatNumber(sale.deal.amount_currency),
              sale.currency,
              sale.deal.lot.broker.name,
              sale.deal.id,
            ]}
          />
          <div>
            <HtmlText
              k={'sale.deal.send-coins-2'}
              args={[sale.deal?.buyer.nickname]}
              textAlign={'center'}
            />
            <HtmlText k={'sale.deal.send-coins-3'} textAlign={'center'} top={'50px'} />
          </div>
          <ActionButton
            caption={'sale.deal.action.send-coins'}
            width={'40vh'}
            onClick={showSendCoinsModal}
            buttonColor={Defaults.greenColor}
            hoverColor={actionHoverColor}
          />
        </>
      )}
    </div>
  );
  const okBlock = status.ok && (
    <div className={classNames(styles.infoContainer, styles.ok)}>
      <BigOkIcon />
      <div className={styles.title}>{`${t('sale.deal.success')}`}</div>
      {backToSiteButton}
      {!voted && (
        <div className={styles.voteActions}>
          <PageText k={'purchase.deal.vote'} after={':'} color={labelColor} />
          <Row top={'1vh'}>
            <ActionButton
              width={'15vh'}
              onClick={() => onUserVote('like')}
              hoverColor={'rgba(0, 151, 219, 0.2)'}
              swapColors>
              <LikeIcon size={pageFontSize} fill={Defaults.mainColor} />
            </ActionButton>
            <ActionButton
              width={'15vh'}
              swapColors
              buttonColor={Defaults.redColor}
              left={'2vh'}
              hoverColor={'rgba(253, 61, 52, 0.2)'}
              onClick={() => onUserVote('dislike')}>
              <LikeIcon size={pageFontSize} fill={Defaults.redColor} flip />
            </ActionButton>
          </Row>
        </div>
      )}
    </div>
  );
  const notOkBlock = status.notOk && (
    <div className={classNames(styles.infoContainer, styles.notOk)}>
      <BigNotOkIcon />
      <div className={styles.title}>{`${t('purchase.canceled')}`}</div>
      {backToSiteButton}
    </div>
  );

  const disputeBlock = isDispute && (
    <div className={classNames(styles.infoContainer, styles.dispute)}>
      <DisputeIcon />
      <div className={styles.title}>{`${t('purchase.deal.dispute-partner-opened')}`}</div>
      <HtmlText k={'purchase.deal.dispute-partner-opened-2'} textAlign={'center'} />
      <HtmlText
        k={'purchase.deal.dispute-partner-opened-3'}
        textAlign={'center'}
        args={[sale.deal.id]}
      />
      {sale.deal.state === 'paid' && (
        <ActionButton
          caption={'sale.deal.action.send-coins'}
          width={'40vh'}
          disabled={busy}
          onClick={showSendCoinsModal}
          buttonColor={Defaults.greenColor}
          hoverColor={actionHoverColor}
        />
      )}
    </div>
  );

  const UnreadMessagesBox = ({ count }: { count?: number }) =>
    !!count && (
      <RoundBox size={'2vh'} fontSize={'1.6vh'} left={'0.4vh'}>
        {count}
      </RoundBox>
    );

  const tabs = {
    info: (
      <div className={styles.tabsInfo}>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.order.merchant')}`}:</div>
          <div className={classNames(styles.value, styles.highlighted)}>
            {sale.merchant.name}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.sky-id')}`}:</div>
          <div className={classNames(styles.value, styles.highlighted)}>
            {sale.deal?.id}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.payment-method')}`}:</div>
          <div className={styles.value}>{sale.deal?.lot.broker.name}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.requisite')}`}:</div>
          <div className={styles.value}>{sale.deal?.requisite}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.get')}`}:</div>
          <div className={styles.value}>
            {sale.deal?.amount_currency} {sale.deal?.lot.currency.toUpperCase()}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.give')}`}:</div>
          <div className={styles.value}>
            {sale.deal?.amount} {sale.deal?.lot.symbol.toUpperCase()}
          </div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.get')}`}:</div>
          <div className={styles.value}>
            {purchase.deal?.amount} {purchase.deal?.symbol.toUpperCase()}
          </div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.identifier')}`}:</div>
          <div className={styles.value}>{sale?.id}</div>
        </div>
        {sale?.label && (
          <div className={styles.row}>
            <div className={styles.name}>{`${t('sale.deal.label')}`}:</div>
            <div className={styles.value}>{sale.label}</div>
          </div>
        )}
      </div>
    ),
    chat: (
      <ChatEx
        user={sale.deal?.buyer.nickname}
        height={'300px'}
        noHeader
        messages={notifications.personalChat.messages}
        onSendMessage={notifications.sendUserMessage}
      />
    ),
    support: (
      <>
        {!notifications.supportChat.isLoading && !notifications.supportChat.isOpened && (
          <div className={styles.openChatContainer}>
            <DealAction
              caption={'purchase.deal.action.open-support-chat'}
              onClick={notifications.startSupportChat}
              width={'300px'}
            />
          </div>
        )}
        {notifications.supportChat.isOpened && (
          <ChatEx
            user={supportUser}
            height={'300px'}
            noHeader
            messages={notifications.supportChat.messages}
            onSendMessage={notifications.sendSupportMessage}
          />
        )}
      </>
    ),
  };

  const needsOrderDetails = useMemo(
    () => voted || sale.status === SALE_STATUSES.NOT_OK,
    [sale.deal?.state, voted],
  );

  const ConfirmSendCoins = () => {
    return (
      <Confirm
        open={showConfirmSendCoins}
        onSubmit={sendCoins}
        onCancel={() => setShowConfirmSendCoins(false)}>
        <HtmlText k={'sale.deal.send-coins-confirmation-1'} size={'2vh'} />
        <HtmlText
          k={'sale.deal.send-coins-confirmation-2'}
          top={'3vh'}
          args={[formatNumber(sale.amount), sale.currency, sale.deal?.buyer.nickname]}
        />
        <HtmlText k={'sale.deal.send-coins-confirmation-4'} top={'1vh'} />
        <HtmlText k={'sale.deal.support-address'} top={'3vh'} />
        <HtmlText
          k={'sale.deal.send-coins-confirmation-5'}
          textAlign={'center'}
          maxWidth={'60vh'}
          top={'3vh'}
        />
      </Confirm>
    );
  };

  return (
    <div className={styles.container}>
      <ConfirmSendCoins />
      {waitingBlock}
      {okBlock}
      {notOkBlock}
      {disputeBlock}
      {needsOrderDetails ? (
        <div className={styles.details}>
          <OrderDetails sale={sale} />
        </div>
      ) : (
        <TabsComponent
          onChange={notifications.setActiveTab}
          className={styles.tabs}
          defaultTab={'info'}
          noGrayscale
          content={{
            ...tabs,
          }}
          tabs={[
            {
              id: 'info',
              icon: <InfoIcon />,
              caption: `sale.deal.tabs.info`,
            },
            {
              id: 'chat',
              icon: <ToMessageIcon />,
              caption: 'sale.deal.tabs.chat',
              component: (
                <UnreadMessagesBox count={notifications.personalChat.unread.length} />
              ),
            },
            {
              id: 'support',
              icon: <ToMessageIcon />,
              caption: 'sale.deal.tabs.support',
              component: (
                <UnreadMessagesBox count={notifications.supportChat.unread.length} />
              ),
            },
          ]}
        />
      )}
    </div>
  );
};
