import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router';
import styled from 'styled-components';

import { Defaults, LinkEx, TextEx } from './components/common';
import { MessageBox } from './components/controls/Dialog';
import Txt from './components/controls/Txt';
import Layout from './components/Layout';
import history, { setPage } from './config/history';
import { tt } from './config/i18n';
import { listenMessages, Message } from './helpers/notifications';
import {
  APP_VERSION,
  CURRENT_TRANSACTION_CURRENCY,
  CURRENT_TRANSACTION_LANG,
  setRequiredLink,
} from './helpers/settings';
import { urlToOpen } from './helpers/utils';
import {
  SWAGGER_ERROR_FIELD_INDEX,
  SWAGGER_ERROR_REGEXP,
  SWAGGER_ERROR_SIGNATURE_INDEX,
} from './utils/fetchApi';
import { removeItem } from './utils/localStorage';

const theme = createTheme({
  typography: {
    fontFamily: Defaults.fontFamily,
  },
});

export const App: React.FC = () => {
  const [error, setError] = React.useState('');
  const [info, setInfo] = React.useState('');
  const [redirect, setRedirect] = React.useState('');
  const [redirectUrl, setRedirectUrl] = React.useState('');
  const [pageOnCancel, setPageOnCancel] = React.useState('');

  listenMessages((message: Message) => {
    switch (message.type) {
      case 'error':
        setError(message.text);
        break;
      case 'info':
        setInfo(message.text);
        break;
      case 'redirect':
        if (message.options) {
          setRedirectUrl(message.options.redirectUrl || '');
          setPageOnCancel(message.options.pageOnCancel || '');
        }
        setRedirect(message.text);
    }
  });

  const onRedirectCancel = () => {
    setRequiredLink(redirectUrl);
    setPage(pageOnCancel);
    setRedirect('');
    setPageOnCancel('');
  };

  useEffect(() => {
    removeItem(CURRENT_TRANSACTION_CURRENCY);
    removeItem(CURRENT_TRANSACTION_LANG);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <NotificationsContainer id={'notifications-container'}>
        <Notification id={'notification'} />
      </NotificationsContainer>
      <Router history={history}>
        <Switch>
          <Route component={Layout} />
        </Switch>
      </Router>
      <Version />
      <ErrorBox open={!!error} error={error} onClose={() => setError('')} />
      <InfoBox open={!!info} info={info} onClose={() => setInfo('')} />
      <RedirectBox
        open={!!redirect}
        text={redirect}
        url={redirectUrl}
        onClose={onRedirectCancel}
      />
    </MuiThemeProvider>
  );
};

const Version = () => (
  <div
    style={{
      position: 'fixed',
      right: '1vh',
      top: '97vh',
      fontFamily: Defaults.fontFamily,
      fontSize: '1vh',
      zIndex: 999,
    }}>
    {APP_VERSION}
  </div>
);

const ignoredMessages = [
  'limit of this lot exceeded',
  'Duplicated deal',
  'No such payment',
];

const ErrorBox = ({
  open,
  error,
  onClose,
}: {
  open: boolean;
  error?: string;
  onClose: () => void;
}) => {
  if (!error || ignoredMessages.includes(error)) {
    return <></>;
  }

  let translated = tt('errors', error, 'unknown');
  const swaggerErrorsMatch = error.match(SWAGGER_ERROR_REGEXP);

  if (translated === 'unknown' && swaggerErrorsMatch && swaggerErrorsMatch.length) {
    translated = `${tt(
      'errors.fields',
      swaggerErrorsMatch[SWAGGER_ERROR_FIELD_INDEX],
    )} ${tt('errors', swaggerErrorsMatch[SWAGGER_ERROR_SIGNATURE_INDEX])}`;
  }

  return (
    <MessageBox open={open} caption={'common.error'} onClose={onClose}>
      <TextEx size={'1.125rem'}>{translated === 'unknown' ? error : translated}</TextEx>
    </MessageBox>
  );
};

const InfoBox = ({
  open,
  info,
  onClose,
}: {
  open: boolean;
  info?: string;
  onClose: () => void;
}) => {
  return info ? (
    <MessageBox open={open} onClose={onClose}>
      <TextEx size={'2vh'}>{info}</TextEx>
    </MessageBox>
  ) : (
    <></>
  );
};

const RedirectBox = ({
  open,
  text,
  url,
  onClose,
}: {
  open: boolean;
  text;
  url: string;
  onClose: () => void;
}) => {
  function openLink() {
    window.location.href = urlToOpen(url);
  }

  return text ? (
    <MessageBox
      open={open}
      closeCaption={'common.goto'}
      onClose={() => {
        openLink();
        onClose();
      }}
      onCancel={onClose}>
      <TextEx size={'5vh'}>&#9888;</TextEx>
      <TextEx size={'2vh'}>
        <Txt k={text} />
      </TextEx>
      <LinkEx size={'2vh'} top={'1vh'} onClick={openLink}>
        {url}
      </LinkEx>
    </MessageBox>
  ) : (
    <></>
  );
};

export const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 99999;
  right: 20px;
  padding: 10px;
  @media (max-width: 768px) {
    bottom: 75px;
    left: 20px;
    right: unset;
  }
`;

export const Notification = styled.div`
  margin-bottom: 10px;
  opacity: 0;
  color: white;
  padding: 10px;
  font-size: 1.2em;
  transition: ${Defaults.transition};
  border-radius: 10px;
`;
