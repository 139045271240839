import React, { ReactElement } from 'react';

import { Defaults, HtmlText } from '../common';
import { actionHoverColor, controlHeight } from '../common/constants';
import { Confirm, DialogTextLine } from '../controls/Dialog';
import { PageReadOnlyValue } from '../controls/Page';
import InputMaskComponent from '../universalComponents/InputMask';
import styles from './styles.module.scss';

export const DealConfirm = ({
  open,
  k,
  args,
  requiredMask,
  value,
  valuePresent,
  busy,
  onChange,
  onSubmit,
  onCancel,
  requisite,
  note,
  noteColor,
  noteArgs,
  disabled,
  customContent,
}: {
  open: boolean;
  k: string;
  args?: any[];
  requiredMask?: boolean;
  busy?: boolean;
  value?: string;
  valuePresent?: boolean;
  onChange?: (value) => void;
  onSubmit;
  onCancel: () => void;
  requisite?: string;
  disabled?: boolean;
  note?: string;
  noteArgs?: (string | number)[];
  noteColor?: string;
  customContent?: ReactElement;
}) => {
  const maskCompleted =
    typeof value === 'string' && value.match(/\d{4}-\d{4}-\d{4}-\d{4}/g);
  const disabledInternal = disabled || (requiredMask && !maskCompleted && !valuePresent);

  return (
    <Confirm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      loadingSubmit={busy}
      disabledSubmit={disabledInternal}
      colors={{
        submitColor: Defaults.greenColor,
        submitHover: actionHoverColor,
      }}>
      <DialogTextLine>
        <HtmlText k={k} size={'2vh'} args={args} />
      </DialogTextLine>
      <DialogTextLine top={'0.6vh'}>
        <HtmlText
          k={note}
          textAlign={'center'}
          args={noteArgs}
          color={noteColor ?? Defaults.redColor}
          weight={'bold'}
        />
      </DialogTextLine>
      {requisite && (
        <div className={styles.info}>
          <div className={styles.requisite}>{requisite}</div>
        </div>
      )}
      {requiredMask && (
        <DialogTextLine maxWidth={'60vh'}>
          <label style={{ width: '100%', textAlign: 'center' }}>
            <HtmlText
              k={`purchase.deal.${valuePresent ? 'sender-requisites' : 'get-requisites'}`}
              size={'14px'}
              color={'red'}
              maxWidth={'60vh'}
              args={args}
            />
          </label>

          <div style={{ margin: '10px 0', display: 'flex', justifyContent: 'center' }}>
            {valuePresent ? (
              <PageReadOnlyValue
                textAlign={'center'}
                fontSize={'2vh'}
                minHeight={controlHeight}
                width={'100%'}
                top={'1vh'}>
                {value}
              </PageReadOnlyValue>
            ) : (
              <InputMaskComponent
                mask="9999-9999-9999-9999"
                value={value || ''}
                onChange={(e) => onChange && onChange(e.target.value)}
                style={{
                  textAlign: 'center',
                  width: '100%',
                  height: 45,
                  borderColor: 'black',
                  fontSize: 24,
                }}
              />
            )}
          </div>
        </DialogTextLine>
      )}
      {customContent}
    </Confirm>
  );
};
