import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Purchase } from '../../../utils/types';
import styles from './styles.module.scss';

interface IOrderDetailsProps {
  purchase: Purchase;
}

export const OrderDetails: FC<IOrderDetailsProps> = ({ purchase }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.orderDetailsContainer}>
      <div className={styles.merchantInfo}>
        <img
          src={purchase.merchant.image_url ?? '/logo.svg'}
          style={
            !purchase.merchant.image_url
              ? { objectFit: 'scale-down', padding: '10px' }
              : {}
          }
          alt="logo"
        />
        <div className={styles.textInfo}>
          <div className={styles.text}>
            {`${t('purchase.order.merchant')}`}:{' '}
            <div className={styles.highlighted}>{purchase.merchant.website}</div>
          </div>
          <div className={styles.amount}>
            {purchase.amount}{' '}
            <span className={styles.currency}>
              {purchase.is_currency_amount ? purchase.currency : purchase.symbol}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.orderInfo}>
        {purchase.label && (
          <div className={styles.text}>
            {`${t('purchase.order.order-num')}`}:{' '}
            <div className={styles.highlighted}>{purchase.label}</div>
          </div>
        )}
        <div className={styles.text}>
          {`${t('purchase.order.payment-id')}`}:{' '}
          <div className={styles.highlighted}>{purchase.payment_id}</div>
        </div>
      </div>
    </div>
  );
};
