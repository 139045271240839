import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '../../../helpers/utils';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import styles from './styles.module.scss';

interface ITabsProps {
  onChange?: (tab: string) => void;
  content: Record<string, ReactNode>;
  tabs: {
    id: string;
    icon?: ReactNode;
    caption: string;
    component?: ReactNode;
  }[];
  defaultTab?: string;
  className?: string;
  centered?: boolean;
  noGrayscale?: boolean;
}

export const TabsComponent: FC<ITabsProps> = ({
  tabs,
  defaultTab,
  content,
  className,
  onChange,
  noGrayscale,
  centered,
}) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(defaultTab ?? tabs[0].id);

  const isMobile = useMediaQuery('max-width: 1024px');

  useEffect(() => {
    onChange(currentTab);
  }, [currentTab]);

  if (isMobile) {
    return (
      <div className={classNames(styles.container, className)}>
        <div className={styles.tabs}>
          {tabs.map((tab) => (
            <>
              <div
                className={classNames(
                  styles.tab,
                  tab.id === currentTab && styles.active,
                  noGrayscale && styles.noGrayscale,
                  centered && styles.centered,
                )}
                key={tab.id}
                onClick={() => setCurrentTab(tab.id)}>
                <div className={classNames(styles.icon)}>{tab.icon}</div>
                <div className={classNames(styles.caption)}>{`${t(tab.caption)}`}</div>
                {tab.component}
              </div>
              {currentTab === tab.id && content[currentTab]}
            </>
          ))}
        </div>
        {/* {content[currentTab]} */}
      </div>
    );
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <div
            className={classNames(
              styles.tab,
              tab.id === currentTab && styles.active,
              noGrayscale && styles.noGrayscale,
              centered && styles.centered,
            )}
            key={tab.id}
            onClick={() =>
              setCurrentTab((current) => (tab.id === current ? 'unset' : tab.id))
            }>
            <div className={classNames(styles.icon)}>{tab.icon}</div>
            <div className={classNames(styles.caption)}>{`${t(tab.caption)}`}</div>
            {tab.component}
          </div>
        ))}
      </div>
      {content[currentTab]}
    </div>
  );
};
