import { FC, useLayoutEffect, useState } from 'react';

import { LanguageEnum, onLanguageChange, setLanguage } from '../../../config/i18n';
import IconEx from '../../controls/IconEx';
import SelectEx from '../../controls/SelectEx';
import styles from './styles.module.css';

interface LanguageSelectorProps {
  lang: string;
  noBorder?: boolean;
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({ lang, noBorder }) => {
  const [language, setLang] = useState(lang);

  useLayoutEffect(() => {
    onLangChange(lang);
  }, [lang]);

  const onLangChange = (id: string) => {
    setLang(id);
    setLanguage(id);
  };

  onLanguageChange(setLang);

  return (
    <div className={styles.languageSelector}>
      <SelectEx
        id={language}
        className={styles.languagePicker}
        optionClassName={styles.langOption}
        onChange={onLangChange}
        borderRadius={'24px'}
        borderColor={!noBorder && 'rgba(0, 0, 0, 0.24)'}
        backgroundColor={'#fff !important'}
        width={'100%'}
        options={[
          {
            id: LanguageEnum.RU,
            value: 'RU',
            img: <IconEx size={28} src={'/ico/top/rus.svg'} />,
            imgPosition: 'left',
          },
          {
            id: LanguageEnum.EN,
            value: 'EN',
            img: <IconEx size={28} src={'/ico/top/usa.svg'} />,
            imgPosition: 'left',
          },
          {
            id: LanguageEnum.UA,
            value: 'UA',
            img: <IconEx size={28} src={'/ico/top/ua.svg'} />,
            imgPosition: 'left',
          },
          {
            id: LanguageEnum.UZ,
            value: 'UZ',
            img: <IconEx size={28} src={'/ico/top/uz.svg'} />,
            imgPosition: 'left',
          },
          {
            id: LanguageEnum.TJ,
            value: 'TJ',
            img: <IconEx size={28} src={'/ico/top/tj.svg'} />,
            imgPosition: 'left',
          },
        ]}
      />
    </div>
  );
};
