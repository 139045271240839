import React, { useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { t } from '../../../../config/i18n';
import {
  getAuthToken,
  setAuthToken,
  setRefreshToken,
} from '../../../../helpers/authHeader';
import { showMessage } from '../../../../helpers/notifications';
import { CAPTCHA_SITE_KEY_V2, setUserEmail } from '../../../../helpers/settings';
import { isEmail, removeSpaces } from '../../../../helpers/utils';
import useFetch from '../../../../hooks/useFetch';
import CPaymentService from '../../../../services/CPaymentService';
import { api } from '../../../../utils/api';
import { isJson } from '../../../../utils/fetchApi';
import { CPayment } from '../../../../utils/types';
import { PasswordCodeDialog } from '../../../blocks/PasswordCodeDialog';
import { blink3, Defaults, UNEXPECTED_ERROR } from '../../../common';
import { BitcoinIcon, EthereumIcon, USDTIcon } from '../../../common/Icons';
import ActionButton from '../../../controls/ActionButton';
import InputEx from '../../../controls/InputEx';
import { pageBorderColor } from '../../../controls/Page';
import SelectEx, { SelectExOptionProps } from '../../../controls/SelectEx';
import Txt from '../../../controls/Txt';
import { Card } from '../../../universalComponents/Card';
import { FormItem } from '../../../universalComponents/FormItem';
import { InfoBlock } from '../../../universalComponents/InfoBlock';
import styles from '../styles.module.css';

interface CryptoPayInitProps {
  id: string;
  payment: CPayment;
  onUpdate: () => void;
}

type Currencies = 'BTC' | 'ETH' | 'USDT';

const StandardsMessages = {
  BTC: 'BEP2, BEP20, ERC20',
  ETH: 'BEP2, BEP20, ERC20',
  USDT: 'BEP2, BEP20, ERC20',
};

const MinimalsMessages = {
  BTC: '0.0002',
  USDT: '5 $',
  ETH: '0.01',
};

export const CryptoPayInit = (props: CryptoPayInitProps) => {
  const [tipOpened, setTipOpened] = useState(true);
  const [currency, setCurrency] = useState<Currencies>('USDT');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordCodeVisible, setPasswordCodeVisible] = useState(false);
  const recaptchaRef = useRef<any>(null);
  const {
    data: { is_available },
    loading,
    refetch: refetchIsAvailable,
  } = useFetch(CPaymentService.getIsETHAvailable, {
    preventOnInit: true,
    initialValue: { is_available: true },
  });

  const isDealAvailable = useMemo(
    () => (currency === 'ETH' ? is_available : true),
    [is_available, currency],
  );

  const setParsedEmail = (str) => setEmail(removeSpaces(str));

  const onCurrencyChange = (currency: Currencies) => {
    if (currency === 'ETH') refetchIsAvailable();
    setCurrency(currency);
    setEmail('');
    setTipOpened(true);
  };

  const showCaptcha = async () => {
    if (recaptchaRef.current) {
      try {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        startPayment(captchaToken);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const registerTempUser = (updateFunc: () => void, recaptchaToken: string) => {
    api.auth
      .registerTemporary(email, recaptchaToken, true)
      .then(({ access, refresh }) => {
        if (access) {
          setAuthToken(access);
          refresh && setRefreshToken(refresh);
          updateFunc();
        } else {
          alert(UNEXPECTED_ERROR);
        }
      })
      .catch((e) => {
        if (e.response) {
          if (isJson(e.response)) {
            e.response.json().then((result) => {
              if (result.detail === 'Such email is used') {
                setPasswordCodeVisible(true);
              } else {
                showMessage('error', result.detail || e.response.statusText);
              }
            });
          } else {
            showMessage('error', e.response.statusText);
          }
        }
      });
  };

  const sendUpdatePayment = () => {
    api.cpayment.start(props.id, currency).then(props.onUpdate).catch(console.error);
  };

  const onAuthorize = (token: string, refresh?: string) => {
    setAuthToken(token);
    refresh && setRefreshToken(refresh);
    startPayment('');
  };

  const startPayment = (captcha: string) => {
    if (!email || !isEmail(email)) {
      return blink3((flag) => setEmailError(flag));
    }

    setUserEmail(email);
    const token = getAuthToken();
    token ? sendUpdatePayment() : registerTempUser(sendUpdatePayment, captcha);
  };

  const currenciesOptions: SelectExOptionProps[] = [
    {
      img: <BitcoinIcon className={styles.currencyIcon} />,
      id: 'BTC',
      caption: 'BTC',
      value: 'Bitcoin',
    },
    {
      img: <EthereumIcon className={styles.currencyIcon} />,
      id: 'ETH',
      caption: 'ETH',
      value: 'Ethereum',
    },
    {
      img: <USDTIcon className={styles.currencyIcon} />,
      id: 'USDT',
      caption: 'USDT (TRC-20)',
      value: 'USDT',
    },
  ];

  return (
    <Card className={styles.card}>
      <div className={styles.cryptoInit}>
        <FormItem label={<Txt k={'crypto-pay.choose-currency'} />}>
          <SelectEx
            id={currency}
            defaultFirst
            onChange={onCurrencyChange}
            options={currenciesOptions}
            className={styles.cryptoSelect}
            borderRadius={6}
          />
        </FormItem>

        <InfoBlock
          closable
          opened={tipOpened}
          onClose={() => setTipOpened(false)}
          type={'attention'}
          title={<Txt k={'crypto-pay.be-warned'} />}>
          <span>
            <span>{<Txt k={`crypto-pay.${currency}.send-only`} />}</span>
            {<Txt k={`crypto-pay.to-this-address`} />}
          </span>
          <span>
            <span className={styles.highlighted}>
              {<Txt k={`crypto-pay.dont-use`} />}
            </span>
            <Txt k={`crypto-pay.other-standards`} args={[StandardsMessages[currency]]} />
            <span className={styles.titled}>
              {<Txt k={`crypto-pay.${currency}.choose-net`} />}
            </span>
          </span>
          <span>{<Txt k={`crypto-pay.other-currencies`} />}</span>
          <span>{<Txt k={`crypto-pay.exchange-rate`} />}</span>
        </InfoBlock>

        <FormItem label={<Txt k={`crypto-pay.email-tip`} />}>
          <InputEx
            type={'text'}
            className={styles.emailInput}
            disabled={!isDealAvailable}
            borderColor={emailError ? Defaults.redColor : pageBorderColor}
            borderRadius={4}
            value={email}
            onChange={setParsedEmail}
          />
        </FormItem>

        <FormItem className={styles.agree} layout={'inline'} label={''}>
          <span className={styles.titled}>{<Txt k={`crypto-pay.agreed`} />}</span>
          <span>{<Txt k={`crypto-pay.minimal-transaction`} />}</span>
          <span className={styles.titled}>
            {Object.keys(MinimalsMessages).map((currency, index, array) => (
              <>
                {`${currency} - `}
                <span className={styles.highlighted}>{MinimalsMessages[currency]}</span>
                {index !== array.length - 1 ? ' / ' : ''}
              </>
            ))}
          </span>
          <span>{<Txt k={`crypto-pay.lower-than-minimal`} />}</span>
          <span className={styles.titled}>{<Txt k={`crypto-pay.on-send`} />}</span>
        </FormItem>

        <PasswordCodeDialog
          open={passwordCodeVisible}
          email={email}
          onSuccess={onAuthorize}
          onClose={() => {
            setPasswordCodeVisible(false);
          }}
        />

        <ActionButton
          disabled={!email || !isDealAvailable}
          className={styles.submitButton}
          busy={loading}
          onClick={showCaptcha}>
          {isDealAvailable ? (
            <Txt k={'crypto-pay.submit'} />
          ) : (
            `${(<Txt k={'crypto-pay.temporary-unavailable'} />)} (${currency})`
          )}
        </ActionButton>

        <div>
          <form>
            <ReCAPTCHA
              sitekey={CAPTCHA_SITE_KEY_V2}
              size="invisible"
              ref={recaptchaRef}
            />
          </form>
        </div>
      </div>
    </Card>
  );
};
