import React from 'react';

import { t } from '../../config/i18n';
import { showMessage } from '../../helpers/notifications';
import { api } from '../../utils/api';
import { Column, Defaults, Row, Spacer } from '../common';
import { MessageBox } from '../controls/Dialog';
import { PageText } from '../controls/Page';
import { PasswordInput } from './PasswordInput';

export const PasswordCodeDialog = ({
  open,
  email,
  busy,
  setBusy = () => ({}),
  onClose,
  onSuccess,
}: {
  open: boolean;
  email: string;
  onClose: () => void;
  onSuccess: (token: string, refresh?: string) => void;
  busy?: boolean;
  setBusy?: (busy: boolean) => void;
}) => {
  const [password, setPassword] = React.useState('');
  const [showCode, setShowCode] = React.useState(false);
  const [code, setCode] = React.useState<string | undefined>(undefined);
  const [error, setErrorInternal] = React.useState<string | undefined>(undefined);

  function setError(e: string) {
    setErrorInternal(e);
    setTimeout(() => setErrorInternal(undefined), 5000);
  }

  function onEnter() {
    if (busy) {
      return;
    }
    setBusy?.(true);
    if (!showCode) {
      if (!password) {
        return setError('auth.error.password-required');
      }
      if (password.length < 8) {
        return setError('auth.error.password-wrong');
      }
    } else {
      if (!code) {
        return setError('auth.error.code-required');
      }
    }
    api.auth
      .login(email, password, code)
      .then((response) => {
        if (response.request_code) {
          setShowCode(true);
          setCode('');
          return;
        }
        if (response.access) {
          onClose();
          onSuccess(response.access, response.refresh);

          setShowCode(false);
          setCode(undefined);
        }
      })
      .catch(() => showMessage('error', t('errors.No such login and password')))
      .finally(() => setBusy?.(false));
  }

  return (
    <MessageBox
      open={open}
      caption={'auth.login.title'}
      onCancel={onClose}
      closeCaption={'auth.login.perform'}
      onClose={onEnter}>
      <Row padding={'1vh 3vh'} noCenter>
        <PageText
          k={!showCode ? 'auth.login.password' : 'auth.mfa.code'}
          after={':'}
          top={'1.2vh'}
        />
        <Column>
          {!showCode ? (
            <PasswordInput value={password} onChange={setPassword} error={!!error} />
          ) : (
            <PasswordInput value={code} onChange={setCode} error={!!error} />
          )}
          {error ? (
            <PageText k={error} size={'1.6vh'} color={Defaults.redColor} left={'1vh'} />
          ) : (
            <Spacer height={'2.3vh'} />
          )}
        </Column>
      </Row>
    </MessageBox>
  );
};
