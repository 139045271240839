import { ReactNode, useEffect, useState } from 'react';

import { classNames } from '../../../helpers/utils';
import { AttentionIcon, CrossIcon, OkIcon } from '../../common/Icons';
import styles from './styles.module.css';

export type InfoBlockTypes = 'info' | 'success' | 'danger' | 'attention';

interface InfoBlockProps {
  closable?: boolean;
  opened?: boolean;
  onClose?: () => void;
  type: InfoBlockTypes;
  customIcon?: ReactNode;
  title?: ReactNode;
  children: ReactNode;
}

const iconsMap = {
  info: <AttentionIcon />,
  attention: <AttentionIcon color={'#FF9500'} />,
  success: <OkIcon />,
  danger: '',
};

export const InfoBlock = (props: InfoBlockProps) => {
  const [isOpened, setIsOpened] = useState(props.opened ?? true);

  useEffect(() => {
    Object.prototype.hasOwnProperty.call(props, 'opened') && setIsOpened(props.opened);
  }, [props.opened]);

  const close = () => {
    setIsOpened(false);
    props.onClose?.();
  };

  return (
    <>
      {isOpened && (
        <div
          className={classNames(styles.infoBlock, styles.container, styles[props.type])}>
          {props.closable && <CrossIcon className={styles.closeIcon} onClick={close} />}
          <span>{props.customIcon ?? iconsMap[props.type]}</span>
          <div className={styles.content}>
            {props.title && <span className={styles.title}>{props.title}</span>}
            {props.children}
          </div>
        </div>
      )}
    </>
  );
};
