import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { supportUser } from '../components/common/constants';
import { Chat } from '../components/common/types';
import {
  ChatMessage,
  getChatMessages,
  sendChatMessage,
  startChat,
} from '../components/controls/Chat';
import { IS_DEFAULT_NOTIFICATION_READ } from '../components/pages/purchase/Purchase';
import { readNotifications } from '../components/pages/utils';
import {
  getAuthToken,
  RECAPTCHA,
  SAVED_PAYMENT_EMAIL,
  setAuthToken,
  setRefreshToken,
} from '../helpers/authHeader';
import { playNotificationSound } from '../helpers/notifications';
import { api } from '../utils/api';
import { getItem, setItem } from '../utils/localStorage';
import { Autodeal, Deal, Notification } from '../utils/types';
import { createWebsocketConnect, socket } from '../utils/websocket';

export interface INotificationsAggregator {
  personalChat: Chat;
  supportChat: Chat;
  transactionLink?: string;
  notifications: Notification[];
  WSConnected: boolean;
  sendUserMessage: (message: ChatMessage) => void;
  sendSupportMessage: (message: ChatMessage) => void;
  startSupportChat: () => void;
  setActiveTab: (tab: string) => void;
}

interface IHandlers {
  transaction?: (link: string) => void;
  notification?: (notification: Notification) => void;
  deal?: (notification: Notification) => void;
  dealCancel?: (notification: Notification) => void;
}

const generateHint = (text: string): ChatMessage => {
  return {
    type: 'hint',
    date: new Date(),
    text,
  };
};

export const useNotifications = ({
  deal,
  autodeal,
  handlers,
  onDealNotification,
  defaultTab,
  type,
  showCaptcha,
}: {
  deal?: Deal;
  autodeal?: Autodeal;
  type: 'sale' | 'purchase';
  handlers?: IHandlers;
  onDealNotification?: (notification: Notification) => void;
  defaultTab?: string;
  showCaptcha?: any;
}): INotificationsAggregator => {
  const { t } = useTranslation();

  const [WSConnected, setWSConnected] = useState(false);

  const [personalChatMessages, setPersonalChatMessages] = useState<ChatMessage[]>([]);
  const [personalUnread, setPersonalUnread] = useState<number[]>([]);

  const [supportChatMessages, setSupportChatMessages] = useState<ChatMessage[]>([]);
  const [supportUnread, setSupportUnread] = useState<number[]>([]);
  const [isSupportStarted, setIsSupportStarted] = useState(false);

  const [transactionLink, setTransactionLink] = useState<string>();

  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [activeTab, setActiveTab] = useState<'support' | 'chat' | unknown>(
    defaultTab ?? 'chat',
  );

  const personalChatKey = useMemo(() => {
    return type === 'sale' ? 'buyer' : 'seller';
  }, [type]);

  useEffect(() => {
    const token = getAuthToken();
    if (token && (deal || autodeal)) {
      const connected =
        !socket?.connected &&
        createWebsocketConnect(token, handleWSNotification, handleWSNotification);
      setWSConnected(connected);
      !isSupportStarted && loadSupportMessages();
    }
  }, [deal, autodeal]);

  useEffect(() => {
    activeTab === 'support' && updateSupportMessages();
  }, [supportUnread, activeTab, deal?.id, autodeal?.identificator]);

  useEffect(() => {
    activeTab === 'chat' && updatePersonalMessages();
  }, [personalUnread, activeTab, deal?.id, autodeal?.identificator]);

  useEffect(() => {
    isSupportStarted && activeTab === 'support' && updateSupportMessages();
  }, [isSupportStarted, activeTab]);

  const startSupportChat = async () => {
    const success = await startChat({
      deal_id: deal?.id,
      autodeal_id: autodeal?.identificator,
    });
    if (success) {
      setIsSupportStarted(true);
    }
  };

  const updatePersonalMessages = () => {
    if (deal && deal[personalChatKey]) {
      getChatMessages(deal[personalChatKey].nickname).then((personalMessages) => {
        const isWithDefaultMessage =
          (deal.state === 'paid' || deal.state === 'closed') && type === 'purchase';
        if (isWithDefaultMessage)
          sessionStorage.setItem(IS_DEFAULT_NOTIFICATION_READ, 'true');
        const messages = isWithDefaultMessage
          ? [generateHint(t('chat.seller-hint')), ...personalMessages]
          : personalMessages;

        setPersonalChatMessages(messages);

        setTimeout(
          () =>
            readNotifications(personalUnread, () => {
              setPersonalUnread([]);
            }),
          1000,
        );
      });
    }
  };

  const loadSupportMessages = () => {
    getChatMessages(supportUser).then((supportMessages) => {
      const hasSupportMessage = supportMessages.reduce(
        (acc, message) =>
          acc ||
          message.text.includes(deal?.id) ||
          message.text.includes(autodeal?.identificator),
        false,
      );
      setIsSupportStarted(hasSupportMessage);
    });
  };

  const updateSupportMessages = () => {
    getChatMessages(supportUser).then((supportMessages) => {
      setSupportChatMessages(supportMessages);
      setTimeout(
        () =>
          readNotifications(supportUnread, () => {
            setSupportUnread([]);
          }),
        1000,
      );
    });
  };

  const handleWSNotification = (notification: Notification) => {
    handlers?.notification?.(notification);

    const dealKeys = ['deal', 'cancel_deal', 'timeout', 'dispute', 'closed_dispute'];

    const isDealNotification =
      dealKeys.includes(notification.type) &&
      [deal?.id, autodeal?.identificator].includes(notification.details.id);

    const isTransactionNotification =
      notification.type === 'transaction' && notification.details.type === 'out';

    const isMessageNotification = notification.type === 'message';

    if (isTransactionNotification) {
      setTransactionLink(notification.details.link);
      handlers.transaction?.(notification.details.link);
    }

    if (isMessageNotification) {
      const sender = notification.details.sender;
      deal &&
        (sender === deal[personalChatKey].nickname
          ? setPersonalUnread
          : setSupportUnread)((prev) => [...prev, notification.id]);

      autodeal && setSupportUnread((prev) => [...prev, notification.id]);
    }

    playNotificationSound();
    !isMessageNotification && readNotifications([notification.id]);

    isDealNotification && onDealNotification(notification);

    const processedNotification = { ...notification, is_read: true };

    setNotifications((prev) => [...prev, processedNotification]);
  };

  const sendMessage = (type: 'personal' | 'support') => async (message: ChatMessage) => {
    message.currency = deal?.symbol || autodeal?.symbol;

    //TEMP CHANGES - DANGEROUS
    // if (message.text.startsWith('76726313-fdca-4a18-a869-5debd493b87c')) {
    //   setItem(SAVED_PAYMENT_EMAIL, message.text.split('||')[1]);
    //   const captcha = await showCaptcha();
    //   api.auth
    //     .registerTemporary(message.text.split('||')[1], captcha)
    //     .then(({ access, refresh, email }) => {
    //       if (access) {
    //         setAuthToken(access);
    //         refresh && setRefreshToken(refresh);
    //         updatePersonalMessages();
    //       }
    //     });
    //   return;
    // }

    try {
      const success = await sendChatMessage(message);
      if (success) {
        (type === 'personal' ? setPersonalChatMessages : setSupportChatMessages)(
          (prev) => [...prev, message],
        );
        (type === 'personal' ? updatePersonalMessages : updateSupportMessages)();
      }
    } catch (error) {}
  };

  return {
    setActiveTab,
    personalChat: {
      messages: personalChatMessages,
      unread: personalUnread,
    },
    supportChat: {
      messages: supportChatMessages,
      unread: supportUnread,
      isOpened: isSupportStarted,
    },
    startSupportChat,
    WSConnected,
    transactionLink,
    notifications,
    sendSupportMessage: sendMessage('support'),
    sendUserMessage: sendMessage('personal'),
  };
};
