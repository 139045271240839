import { HTMLAttributes } from 'react';

import { classNames } from '../../../helpers/utils';
import styles from './styles.module.css';

interface FormItemProps extends HTMLAttributes<HTMLDivElement> {
  label: string | JSX.Element;
  children: JSX.Element | JSX.Element[] | string;
  layout?: 'inline' | 'block';
}

export const FormItem = ({ layout, label, className, ...props }: FormItemProps) => {
  return (
    <div
      className={classNames(
        styles.formItem,
        layout ? styles[layout] : styles.block,
        className,
      )}
      {...props}>
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
