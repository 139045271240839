import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FlashPayBroker, Merchant, PurchaseV2, SaleV2 } from '../../../../utils/types';
import { Defaults, TextEx } from '../../../common';
import ActionButton from '../../../controls/ActionButton';
import IconEx from '../../../controls/IconEx';
import InputMask from '../../../universalComponents/InputMask';
import { LoadingWrapper } from '../../../universalComponents/LoadingWrapper';
import { FlashPayType } from '../FlashPay';
import styles from '../styles.module.css';

type FlashPayInitProps = {
  onBrokerSelect: (broker: FlashPayBroker, mask?: string) => void;
  brokers: FlashPayBroker[];
  deal?: SaleV2 | PurchaseV2;
  merchant?: Merchant;
  type: FlashPayType;
};

export const FlashPayInit: FC<FlashPayInitProps> = ({
  brokers,
  onBrokerSelect,
  deal,
  type,
  merchant,
}) => {
  const { t } = useTranslation();

  const [currentBroker, setCurrentBroker] = useState<FlashPayBroker>();
  const [mask, setMask] = useState<string>('');

  useEffect(() => {
    merchant && !merchant.required_mask && onBrokerSelect(currentBroker);
  }, [currentBroker?.broker_id]);

  const onMaskChange = (e) => {
    setMask(e.target.value);
  };

  const onMaskSend = () => {
    onBrokerSelect(currentBroker, mask);
  };

  const onCancel = () => {
    setCurrentBroker(undefined);
  };

  const maskRequired = useMemo(() => {
    return (
      type === 'payment' && currentBroker && merchant?.required_mask && !deal?.['mask']
    );
  }, [type, merchant?.required_mask, currentBroker?.broker_id, deal?.['mask']]);

  const maskComplete = useMemo(() => {
    return typeof mask === 'string' && !!mask.match(/\d{4}-\d{4}-\d{4}-\d{4}/g);
  }, [mask]);

  console.log(brokers);

  return (
    <div className={styles.initContainer}>
      {type === 'payment' ? (
        <LoadingWrapper isLoading={!brokers}>
          {maskRequired ? (
            <>
              <div className={styles.maskContainer}>
                <TextEx className={styles.maskText} size={15}>{`${t(
                  'flash-pay.enter-card-num',
                )}`}</TextEx>
                <div className={styles.maskContentContainer}>
                  <InputMask
                    mask="9999-9999-9999-9999"
                    value={mask || ''}
                    onChange={onMaskChange}
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      height: 45,
                      border: 'unset',
                      fontSize: 24,
                    }}
                  />
                  <TextEx
                    color={Defaults.redColor}
                    size={12}
                    className={styles.maskText}>{`${t(
                    'flash-pay.mask-warning',
                  )}`}</TextEx>
                  <div className={styles.maskButtons}>
                    <ActionButton
                      caption={'common.cancel'}
                      onClick={onCancel}
                      buttonColor={Defaults.redColor}
                      hoverColor={Defaults.redColor}
                      swapColors
                      customStyle={'flex-grow: 1'}
                    />
                    <ActionButton
                      caption={'common.confirm'}
                      onClick={onMaskSend}
                      disabled={!maskComplete}
                      buttonColor={Defaults.mainColor}
                      customStyle={'flex-grow: 1'}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <span className={styles.headerText}>{`${t('flash-pay.choose-bank')}`}</span>
              <div className={styles.brokersContainer}>
                {brokers?.map((pm, n) => {
                  return (
                    <div
                      className={styles.brokerContainer}
                      key={n}
                      onClick={() => setCurrentBroker(pm)}>
                      {pm.logo && <IconEx src={pm.logo} size={'40px'} alt={'logo'} />}
                      <TextEx textAlign={'center'}>{pm.name}</TextEx>
                    </div>
                  );
                }) || undefined}
              </div>
            </>
          )}
        </LoadingWrapper>
      ) : (
        <LoadingWrapper isLoading={true} description={t('flash-pay.requisites-waiting')}>
          {''}
        </LoadingWrapper>
      )}
    </div>
  );
};
