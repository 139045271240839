import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { Route, Router, Switch } from 'react-router';
import styled from 'styled-components';

import history from '../config/history';
import PageNotFound from './PageNotFound';
import { CryptoPay } from './pages/cryptoPay/CryptoPay';
import CryptoPayLandingPage from './pages/cryptoPay/CryptoPayLandingPage';
import PagePaymentError from './pages/errors/PagePaymentError';
import { FlashPay } from './pages/flashPay/FlashPay';
import { PurchasePage as PurchasePageNew } from './pages/purchase/Purchase';
import { SalePage as Sale } from './pages/sale/Sale';

export default class Layout extends React.Component {
  public render(): React.ReactNode {
    return (
      <LayoutRoot>
        <CssBaseline />
        <Main>
          <Router history={history}>
            <Switch>
              <Route exact path={'/cpayment/:id'} component={CryptoPayLandingPage} />
              <Route exact path={'/cpayment/:id/embedded'} component={CryptoPay} />
              <Route exact path={'/payment/:id'} component={PurchasePageNew} />
              <Route exact path={'/payment-error'} component={PagePaymentError} />
              <Route exact path={'/sale/:id'} component={Sale} />
              <Route exact path={'/flashpay/:type/:id'} component={FlashPay} />
              <Route component={PageNotFound} />
            </Switch>
          </Router>
        </Main>
      </LayoutRoot>
    );
  }
}

const LayoutRoot = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #fff;
`;
const Main = styled.main`
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 480px) {
    margin-left: unset;
    margin-right: unset;
  }
`;
