import { io, Socket } from 'socket.io-client';

import { parseJwt } from '../helpers/settings';

export let socket: Socket | null = null;

export const useSocket = () => {
  return {
    socket,
  };
};

export const createWebsocketConnect = (
  token: string,
  updateCallback?: (notification) => void,
  nullCallback?: (notification) => void,
): boolean => {
  if (!token) return false;
  const data = parseJwt(token);
  if (data && data.user_id && data.nickname) {
    const WS_URL = process.env.REACT_APP_WS_URL || `ws://localhost:8080/`;
    socket = io(WS_URL, {
      path: '/sky-socket',
      reconnectionAttempts: 3,
      timeout: 3000,
      auth: { id: data.user_id, name: data.nickname },
    });

    updateCallback && socket.on('update deal', updateCallback);
    updateCallback && socket.on(null, nullCallback);
    return true;
  }
  return false;
};
