import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { classNames } from '../../../helpers/utils';
import styles from './styles.module.css';

interface Step {
  label?: string;
  content: ReactNode;
  id: string;
}

export interface StepsProps {
  initialStep?: string;
  currentStep: string;
  gap?: number | string;
  header?: (step?: string) => ReactNode;
  steps: Step[];
  onStep?: (id: string) => void;
}

export const Steps = (props: StepsProps) => {
  const [currentStep, setCurrentStep] = useState(props.initialStep ?? props.steps[0].id);

  useEffect(() => {
    setCurrentStep(props.currentStep);
  }, [props.currentStep]);

  const stepsIndices = useMemo(() => {
    return props.steps.reduce((acc, step, index) => ({ ...acc, [step.id]: index }), {});
  }, [props.steps]);

  return (
    <div className={styles.steps}>
      <div className={styles.stepsContainer} style={{ marginBottom: props.gap ?? 20 }}>
        {props.steps.map((step) => (
          <p
            className={classNames(
              styles.step,
              stepsIndices[step.id] <= stepsIndices[currentStep] && styles.active,
            )}
          />
        ))}
      </div>
      {props.header && props.header(currentStep)}
      <div className={styles.stepsContent}>
        {props.steps[stepsIndices[currentStep]].content}
      </div>
    </div>
  );
};
