import React from 'react';
import styled from 'styled-components';

import { Defaults, px } from '../../common';
import ActionButton from '../../controls/ActionButton';
import { PageText } from '../../controls/Page';
import { actionHoverColor } from '../constants';

export const PanelRowRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => px(props.top)};

  & .children {
    margin-top: 2vh;
  }
`;

export const LabelControl = ({
  label,
  children,
  top,
}: {
  label: string;
  children: React.ReactNode;
  top?: string | number;
}) => (
  <PanelRowRoot top={top}>
    <PageText k={label} after={':'} uppercase />
    <div className={'children'}>{children}</div>
  </PanelRowRoot>
);

export const DealAction = ({
  caption,
  top,
  width,
  busy = false,
  className,
  swapColors,
  onClick,
  disabled,
}: {
  caption: string;
  top?;
  width?: string;
  busy?: boolean;
  onClick: () => void;
  swapColors?: boolean;
  disabled?: boolean;
  className?: string;
}) => (
  <ActionButton
    className={className}
    caption={caption}
    top={top}
    width={width}
    onClick={onClick}
    disabled={disabled}
    buttonColor={Defaults.greenColor}
    hoverColor={actionHoverColor}
    busy={busy}
    swapColors={swapColors}
  />
);

export const DealCancelAction = ({
  caption,
  top,
  left,
  width = '20vh',
  busy = false,
  onClick,
  className,
}: {
  caption: string;
  top?;
  left?;
  width?: string;
  busy?: boolean;
  onClick: () => void;
  className?: string;
}) => (
  <ActionButton
    className={className}
    caption={caption}
    onClick={onClick}
    busy={busy}
    left={left}
    top={top}
    width={width}
    buttonColor={Defaults.redColor}
    hoverColor={Defaults.redColor}
    swapColors
  />
);
