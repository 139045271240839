import { getAuthHeader } from '../helpers/authHeader';
import { API_V1, API_V2, fetchApi } from './fetchApi';
import {
  Autodeal,
  Broker,
  Commission,
  CPayment,
  Deal,
  DealBid,
  DealResponse,
  DealType,
  FlashPayBroker,
  Merchant,
  Message,
  Notification,
  Purchase,
  PurchaseV2,
  Sale,
  SaleV2,
  SendMessage,
} from './types';

const Stamp = '8758399327742';

export const api = {
  auth: {
    login: (
      email,
      password,
      code?: string,
    ): Promise<{ access?: string; refresh?: string; request_code?: boolean }> =>
      fetchApi(API_V1, '/auth/login', {
        method: 'POST',
        body: { email, password, code },
      }),
    registerEmail: (email: string): Promise<{ nickname: string }> =>
      fetchApi(API_V1, '/auth/register', { method: 'POST', body: { email } }),
    validateEmail: (email, code: string): Promise<{ is_valid: boolean }> =>
      fetchApi(API_V1, '/auth/validate', {
        method: 'POST',
        body: { email, code },
      }),
    registerComplete: (
      email,
      code,
      password,
      secret,
      ref?,
      cmp?: string,
    ): Promise<{ success: string }> =>
      fetchApi(API_V1, '/auth/complete-register', {
        method: 'POST',
        body: { email, code, password, secret, ref, cmp },
      }),
    registerTemporary: (
      email: string,
      recaptchaToken: string,
      suppressError?: boolean,
    ): Promise<{ access: string; refresh?: string; email: string }> =>
      fetchApi(API_V1, '/auth/fast-register', {
        method: 'POST',
        body: { email, captcha_token: recaptchaToken },
        suppressError,
      }),
  },

  settings: {
    transfer: (): Promise<Commission[]> =>
      fetchApi(API_V1, '/settings', { headers: getAuthHeader() }),
  },

  cpayment: {
    get: (id: string): Promise<CPayment> =>
      fetchApi(API_V1, `/cp/${id}`, { headers: { Stamp }, suppressError: true }),
    rates: (): Promise<any> => fetchApi(API_V1, '/rates', {}),
    start: (id: string, symbol: string): Promise<any> =>
      fetchApi(API_V1, `/cp/${id}/start`, {
        method: 'POST',
        body: { symbol: symbol.toLowerCase() },
        headers: { ...getAuthHeader(), Stamp },
      }),
  },

  messages: {
    start: (
      receiver: string,
      ids: {
        deal_id?: string;
        autodeal_id?: string;
      },
    ): Promise<boolean> =>
      fetchApi(API_V1, '/user-messages/open-chat-message', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { receiver, ...ids },
      }),
    receive: (user?: string): Promise<Message[]> =>
      fetchApi(API_V1, '/user-messages', {
        headers: getAuthHeader(),
        params: { with_user: user },
      }),
    send: (message: SendMessage) =>
      fetchApi(API_V1, '/user-messages', {
        method: 'POST',
        headers: getAuthHeader(),
        body: message,
      }),
    attach: (file: any): Promise<{ id: number }> =>
      fetchApi(API_V1, '/media', {
        method: 'POST',
        headers: { ...getAuthHeader(), 'Content-Type': '' },
        formData: formData({ file }),
      }),
  },

  autodeal: {
    get: (id: string): Promise<Autodeal> =>
      fetchApi(API_V1, `/autodeals/${id}`, {
        headers: getAuthHeader(),
        suppressError: true,
      }),
    cancel: (id: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/autodeals/cancel', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { autodeal_id: id },
      }),
    run: (id: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/autodeals/states', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { autodeal_id: id },
      }),
  },

  deal: {
    get: (id: string): Promise<Deal> =>
      fetchApi(API_V1, `/deals/${id}`, {
        headers: getAuthHeader(),
        suppressError: true,
      }),
    new: (
      dealType: DealType,
      bidId: string,
      amountCurrency?: number,
      amountCrypto?: number,
      paymentId?,
      saleId?: string,
    ): Promise<Deal> =>
      fetchApi(API_V1, '/deals', {
        method: 'POST',
        headers: getAuthHeader(),
        body: {
          lot_id: bidId,
          payment_id: paymentId,
          sell_id: saleId,
          amount_currency: amountCurrency,
          amount: amountCrypto,
          type: dealType,
        },
      }),
    setRequisite: (id, requisite: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/deals/requisite', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { deal_id: id, requisite },
      }),
    run: (id: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/deals/states', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { deal_id: id },
      }),
    runWithMask: (id: string, mask?: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/deals/mask', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { deal_id: id, mask },
        suppressError: true,
      }),
    cancel: (id: string): Promise<DealResponse> =>
      fetchApi(API_V1, '/deals/cancel', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { deal_id: id },
      }),
    openDispute: (id: string) =>
      fetchApi(API_V1, '/disputes', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { deal_id: id },
      }),
    voteUser: (dealId, user: string, method: 'like' | 'dislike'): Promise<DealResponse> =>
      fetchApi(API_V1, '/users/vote', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { deal_id: dealId, user, method },
      }),

    notifications: (limit: number): Promise<Notification[]> =>
      fetchApi(API_V1, '/operations/updates', {
        headers: getAuthHeader(),
        params: { limit },
        suppressError: true,
      }),
    readNotifications: (ids: number[]) =>
      fetchApi(API_V1, '/operations/updates', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { ids, is_read: true },
      }),
  },

  flashpay: {
    getPurchase: (id): Promise<PurchaseV2> => fetchApi(API_V1, `/p2/${id}`, {}),
    getSale: (id): Promise<SaleV2> => fetchApi(API_V1, `/s2/${id}`, {}),
    patch: ({ id, brokerId, mask }): Promise<PurchaseV2> =>
      fetchApi(API_V1, `/p2/${id}`, {
        method: 'PATCH',
        body: { broker_id: brokerId, mask },
      }),
  },

  purchases: {
    getV2: (id): Promise<PurchaseV2> =>
      fetchApi(API_V2, `/payments_v2/${id}`, { headers: { Stamp } }),
    get: (id): Promise<Purchase> =>
      fetchApi(API_V1, `/p/${id}`, { headers: { Stamp }, suppressError: true }),
    findBid: (
      symbol: string,
      exclude: string[],
      paymentId: string,
      amountCrypto?: number,
      amountCurrency?: number,
      brokerId?: string,
    ): Promise<DealBid> =>
      fetchApi(API_V1, '/lots/fast-deal', {
        headers: getAuthHeader(),
        params: {
          symbol,
          amount: amountCrypto,
          amount_currency: amountCurrency,
          exclude: exclude.join(','),
          payment_id: paymentId,
          broker: brokerId,
        },
      }),
    postPageOpened: (purchaseId: string) =>
      fetchApi(API_V1, `/open-purchase-page/${purchaseId}`, { method: 'POST' }),
    findAndStartBid: (
      symbol: string,
      exclude: string[],
      paymentId: string,
      amountCrypto?: number,
      amountCurrency?: number,
      brokerId?: string,
      address?: string,
      currency?: string,
    ): Promise<{ deal?: Deal; autodeal?: Autodeal }> =>
      fetchApi(API_V1, '/lots/fast-deal-start', {
        headers: getAuthHeader(),
        params: {
          symbol,
          amount: amountCrypto,
          amount_currency: amountCurrency,
          exclude: exclude.join(','),
          payment_id: paymentId,
          broker: brokerId,
          address,
          currency,
        },
      }),
    updateV2: (
      id,
      symbol: string,
      amount,
      status,
      buyer,
      merchant: number,
      label?: string,
    ) =>
      fetchApi(API_V2, `/p/${id}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp },
        body: {
          symbol,
          amount,
          status,
          buyer_id: buyer,
          merchant_id: merchant,
          label,
        },
      }),
    update: (id, body: Record<string, any>) =>
      fetchApi(API_V1, `/p/${id}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp },
        body,
      }),
    setToken: (id, token: string) =>
      fetchApi(API_V1, `/p/${id}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp },
        body: { token },
      }),
    getMerchant: (id: number): Promise<Merchant> =>
      fetchApi(API_V1, `/merchant/${id}`, {}),
    getBrokers: (
      currency: string,
      available_for: 'sky-pay' | 'sky-sale',
      symbol?: string,
      merchant_id?: number,
      payment_id?: string,
      amount?: number,
      amount_currency?: number,
    ): Promise<Broker[]> =>
      fetchApi(API_V1, '/brokers', {
        headers: getAuthHeader(),
        params: {
          merchant_id,
          currency,
          sky_pay: true,
          symbol,
          with_availability_status: true,
          available_for,
          amount,
          amount_currency,
          payment_id,
        },
      }),
    getFlashPayBrokers: (currency: string): Promise<FlashPayBroker[]> =>
      fetchApi(API_V1, '/brokers/flashpay', {
        params: {
          currency,
        },
      }),
  },
  sales: {
    getV2: (id): Promise<SaleV2> =>
      fetchApi(API_V2, `/sale_v2/${id}`, { headers: { Stamp } }),
    get: (id): Promise<Sale> =>
      fetchApi(API_V1, `/s/${id}`, { headers: { Stamp }, suppressError: true }),
    setToken: (id, token: string) =>
      fetchApi(API_V1, `/s/${id}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp },
        body: { token },
      }),
    findBid: (
      broker,
      symbol: string,
      exclude: string[],
      amountCrypto?,
      amountCurrency?: number,
      currency?: string,
    ): Promise<DealBid> =>
      fetchApi(API_V1, '/lots/api-sell', {
        headers: getAuthHeader(),
        params: {
          symbol,
          broker,
          exclude: exclude.join(','),
          amount: amountCrypto,
          amount_currency: amountCurrency,
          currency,
        },
      }),
  },
};

function formData(params: Record<string, any>): FormData {
  const data = new FormData();
  for (const name in params) {
    if (Object.prototype.hasOwnProperty.call(params, name)) {
      data.append(name, params[name]);
    }
  }
  return data;
}

export function updater(
  update: () => void,
  interval = 10000,
  disableCondition = false,
): () => void {
  if (disableCondition) return () => ({});
  update();
  const intervalId = setInterval(update, interval);
  return () => clearInterval(intervalId);
}
