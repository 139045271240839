import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames, copyToClipboard } from '../../../helpers/utils';
import { Broker, FlashPayBroker } from '../../../utils/types';
import { Timer } from '../../common';
import IconEx from '../../controls/IconEx';
import InputEx from '../../controls/InputEx';
import { CopyIcon } from '../Icons';
import styles from './styles.module.scss';

interface PayCardProps {
  requisites?: string;
  onSetRequisite?: (value: string) => void;
  editable?: boolean;
  placeholder?: string;
  broker?: Broker | FlashPayBroker;
  hint?: string;
  expireIn?: number;
  selfRequisites?: boolean;
  requisitesText?: string;
  className?: string;
  separated?: boolean;
}

export const PayCard: FC<PayCardProps> = ({
  broker,
  requisites,
  selfRequisites,
  hint,
  onSetRequisite,
  placeholder,
  editable,
  expireIn,
  requisitesText,
  className,
  separated,
}) => {
  const { t } = useTranslation();

  const numPart = useMemo(() => {
    const firstNonRequisiteSymbolIndex = requisites
      .split('')
      .reduce((acc, symbol, index) => {
        if (acc) return acc;
        if (Number.isNaN(+symbol)) return index;
      }, 0);
    if (firstNonRequisiteSymbolIndex <= 2) return '';
    return requisites.slice(0, firstNonRequisiteSymbolIndex).trim();
  }, [requisites]);

  const onCopy = () => {
    copyToClipboard(requisites);
  };

  const onRequisitesCopy = () => {
    copyToClipboard(numPart);
  };

  return (
    <div className={classNames(styles.payCard, className)}>
      <div className={styles.header}>
        <div className={styles.broker}>
          {broker?.logo && <IconEx src={broker.logo} size={'40px'} alt={'logo'} />}
          {broker?.name.toUpperCase()}
        </div>
        {expireIn && <Timer time={expireIn * 2} isNew />}
      </div>
      <div className={styles.requisitesBlock}>
        <div className={styles.requisitesText}>{`${
          requisitesText ?? t(selfRequisites ? 'common.requisites' : 'common.card-num')
        }`}</div>
        {separated && !!numPart.length && (
          <div className={classNames(styles.info)}>
            <div className={styles.requisite}>{numPart}</div>
            <CopyIcon onClick={onRequisitesCopy} className={styles.copyIcon} />
          </div>
        )}
        {separated && !!numPart.length && (
          <span className={classNames(styles.fieldName)}>
            {`${t('common.full-requisites')}:`}
          </span>
        )}
        <div className={classNames(styles.info, editable && styles.editable)}>
          {editable ? (
            <InputEx
              maxLength={199}
              className={styles.editable}
              value={requisites}
              onChange={onSetRequisite}
              hint={placeholder}
              type={'text'}
            />
          ) : (
            <div className={styles.requisite}>{requisites}</div>
          )}
          <CopyIcon onClick={onCopy} className={styles.copyIcon} />
        </div>
        {hint && <div className={styles.hint}>{hint}</div>}
      </div>
    </div>
  );
};
