import React from 'react';
import styled from 'styled-components';

import { Defaults, HtmlText, px, Row } from '../common';
import { PageText } from '../controls/Page';

const PurchaseHint = ({
  caption,
  description,
  expand,
  onClick,
  src,
}: {
  src;
  caption;
  description: string;
  expand: boolean;
  onClick: () => void;
}) => (
  <PurchaseHintRoot onClick={onClick} className={expand ? 'expand' : 'collapse'}>
    <Row noCenter>
      <div className={'caption'}>
        <PageText k={caption} color={!expand ? Defaults.mainColor : undefined} />
      </div>
    </Row>
    <div className={`description ${expand ? 'show' : 'hide'}`}>
      <HtmlText
        k={description}
        args={[src]}
        size={'1.6vh'}
        weight={300}
        lineHeight={'3vh'}
      />
    </div>
  </PurchaseHintRoot>
);

const PurchaseHintRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 82vh;
  width: 100%;
  padding: 2vh;
  border-radius: ${px(Defaults.borderRadius)};
  border: 0.1vh dashed transparent;
  margin-bottom: 3vh;
  cursor: pointer;
  box-shadow: 0.2vh 0.2vh 3vh rgba(0, 0, 0, 0.1);

  & .caption {
    flex-grow: 1;
  }

  &.collapse {
    background-color: white;
  }
  &.expand {
    border-color: ${Defaults.grayColor};
  }

  & .description {
    height: 0;
    overflow: hidden;
  }
  & .description.show {
    height: auto;
    margin-top: 1vh;
  }
`;

export default PurchaseHint;
