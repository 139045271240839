import { HTMLAttributes } from 'react';

import { classNames } from '../../../helpers/utils';
import styles from './styles.module.css';

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  children: JSX.Element | JSX.Element[];
}

export const Card = ({ className, ...props }: CardProps) => {
  return (
    <div className={classNames(styles.card, className)} {...props}>
      {props.children}
    </div>
  );
};
