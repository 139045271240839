export type MessageType = 'info' | 'warn' | 'error' | 'redirect';

export interface Message {
  type: MessageType;
  text: string;
  options?: MessageOptions;
}

export interface MessageOptions {
  redirectUrl?: string;
  pageOnCancel?: string;
}

export type MessagesListener = (message: Message) => void;

const messagesListeners: MessagesListener[] = [];

export function listenMessages(listener: MessagesListener) {
  messagesListeners.push(listener);
}

export function stopListenMessages(listener: MessagesListener) {
  const n = messagesListeners.indexOf(listener);
  if (n !== -1) {
    messagesListeners.splice(n, 1);
  }
}

export function showMessage(type, text: string, options?: MessageOptions) {
  for (const listener of messagesListeners) {
    listener({ type, text, options });
  }
}

export interface Notifications {
  deals: Record<string, number[]>;
  disputes: Record<string, number[]>;
  messages: Record<string, number[]>;
  unreadGroups: number;
}

export type NotificationsListener = (notifications: Notifications) => void;

const notificationsListeners: NotificationsListener[] = [];

export function listenNotifications(listener: NotificationsListener) {
  notificationsListeners.push(listener);
}

export function stopListenNotifications(listener: NotificationsListener) {
  const n = notificationsListeners.indexOf(listener);
  if (n !== -1) {
    notificationsListeners.splice(n, 1);
  }
}

export function newNotifications(notifications: Notifications) {
  for (const listener of notificationsListeners) {
    listener(notifications);
  }
}

const notificationSound = new Audio('/sound/time-is-now.mp3');
notificationSound.load();

let notificationSoundPlaying = false;

export function playNotificationSound() {
  if (!notificationSoundPlaying) {
    notificationSoundPlaying = true;
    notificationSound.load();
    notificationSound
      .play()
      .then()
      .catch((e) => console.log('cannot play sound:', e))
      .finally(() => (notificationSoundPlaying = false));
  }
}
