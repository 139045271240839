import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { onLanguageChange, tt } from '../../config/i18n';
import { classNames, openLink, parseDate, toLowerCase } from '../../helpers/utils';
import { api } from '../../utils/api';
import { Defaults, Hr, PendingIcon, px, Spacer, TextEx, Timer } from '../common';
import { supportUser } from '../common/constants';
import { AttachmentBox } from './AttachmentBox';
import IconEx from './IconEx';
import { pageFontSize, pageInfoCaptionColor } from './Page';
import PDFEx from './PDFEx';
import Txt from './Txt';

export type ChatUserStatus = 'online' | 'offline';
export type ChatMessageType = 'user' | 'system' | 'date' | 'hint' | 'select';

export interface ChatMessage {
  type: ChatMessageType;
  date: Date;
  user?: string;
  text?: string;
  mediaId?: number;
  media?: string;
  options?: string[];
  icons?: string[];
  hideCaptions?: boolean;
  customData?: any;
  selected?: string;
  onSelect?: (option: string, message?: ChatMessage) => void;
  currency?: string;
}

export function startChat(ids: {
  deal_id?: string;
  autodeal_id?: string;
}): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    api.messages
      .start(supportUser, ids)
      .then(() => resolve(true))
      .catch(() => resolve(false));
  });
}

export function getChatMessages(user, token?: string): Promise<ChatMessage[]> {
  return new Promise<ChatMessage[]>((resolve) =>
    api.messages
      .receive(user)
      .then((userMessages) => {
        const messages: ChatMessage[] = [];
        if (userMessages) {
          for (const message of userMessages) {
            messages.push({
              type: message.sender === user ? 'system' : 'user',
              date: parseDate(message.created_at) || new Date(),
              user: message.sender,
              text: message.message,
              media: message.media,
            });
          }
          messages.sort((a, b) => (a.date > b.date ? 1 : -1));
        }
        resolve(messages);
      })
      .catch(() => resolve([])),
  );
}

export function sendChatMessage(message: ChatMessage): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    if (!!message.user && (!!message.text || !!message.mediaId)) {
      api.messages
        .send({
          receiver: message.user,
          message: message.text,
          media_id: message.mediaId,
          symbol: toLowerCase(message.currency),
        })
        .then(() => resolve(true))
        .catch(() => resolve(false));
    } else {
      resolve(false);
    }
  });
}

const sendTimeout = 20;

const ChatEx = ({
  user,
  alias,
  messages,
  onSendMessage,
  userStatus = 'online',
  captionPrefix,
  toolbar,
  userIcon,
  height,
  minWidth,
  icons,
  noHeader = false,
}: {
  user;
  alias?: string;
  messages: ChatMessage[];
  onSendMessage: (message: ChatMessage) => void;
  userStatus?: ChatUserStatus;
  captionPrefix?: string;
  toolbar?: React.ReactNode;
  userIcon?: React.ReactNode;
  height?;
  minWidth?: number | string;
  icons?: Record<string, React.ReactNode>;
  noHeader?: boolean;
}) => {
  const [inputMessage, setInputMessage] = useState<string>('');
  const [lockTime, setLockTime] = useState(0);
  const [pendingMessage, setPendingMessage] = useState<ChatMessage | null>(null);
  const chatContentRef = useRef<HTMLDivElement>(null);

  useEffect(scrollChatContent, [messages.length]);
  useEffect(() => {
    const lastMessages = messages.slice(-10);
    if (
      pendingMessage &&
      lastMessages.find(
        (message) =>
          (!message.media && message.text == pendingMessage.text) ||
          (message.mediaId && message.mediaId == pendingMessage.mediaId),
      )
    )
      setPendingMessage(null);
  }, [messages]);

  const [, setLang] = React.useState<string>('');
  onLanguageChange((lang) => setLang(lang));

  function attachFiles(e: ChangeEvent<HTMLInputElement>) {
    if (!e.target || !e.target.files || e.target.files.length === 0) {
      return;
    }
    api.messages
      .attach(e.target.files[0])
      .then((result) => {
        if (!!result && !!result.id) {
          const formedMessage: ChatMessage = {
            type: 'user',
            date: new Date(),
            user,
            mediaId: result.id,
          };
          onSendMessage(formedMessage);
          setPendingMessage(formedMessage);
        }
      })
      .finally(lockSending);
  }

  function sendMessage() {
    if (lockTime === 0 && !!inputMessage) {
      const message: ChatMessage = {
        type: 'user',
        date: new Date(),
        user,
        text: inputMessage,
      };

      setInputMessage('');
      setPendingMessage(message);
      onSendMessage(message);

      lockSending();
    }
  }

  function lockSending() {
    let lTime = sendTimeout * 500;
    setLockTime(sendTimeout);
    const lockTimer = setInterval(() => {
      lTime -= 500;
      setLockTime(lTime / 500);
      if (lTime <= 0) {
        clearInterval(lockTimer);
      }
    }, 500);
  }

  function scrollChatContent() {
    const chat = chatContentRef.current!;
    chat.scrollTop = chat.scrollHeight;
  }

  return (
    <ChatRoot height={height} minWidth={minWidth}>
      {!noHeader ? (
        <>
          <ChatCaptionRoot>
            {userIcon ? userIcon : undefined}
            <ChatCaptionText>
              <TextEx>
                {captionPrefix ? (
                  <>
                    <Txt k={captionPrefix} />
                    &nbsp;
                  </>
                ) : undefined}
                <TextEx color={Defaults.mainColor}>
                  {alias ? <Txt k={alias} /> : `/${user}`}
                </TextEx>
              </TextEx>
              <TextEx size={'1.6vh'} color={pageInfoCaptionColor}>
                <Txt k={`chat.status-${userStatus}`} />
              </TextEx>
            </ChatCaptionText>
            {toolbar ? (
              <>
                <Spacer />
                {toolbar}
              </>
            ) : undefined}
          </ChatCaptionRoot>
          <Hr />
        </>
      ) : undefined}
      <ChatContent ref={chatContentRef}>
        <Spacer />
        {insertDates(messages).map((message, i) => (
          <ChatMessageBox key={i} message={message} alias={alias} icons={icons} />
        ))}
        {pendingMessage && (
          <ChatMessageBox
            key={'pendingMessage'}
            message={pendingMessage}
            pending
            alias={alias}
            icons={icons}
          />
        )}
      </ChatContent>
      <Hr />
      <ChatFooter>
        <Translation>
          {(t) => (
            <>
              <AttachmentBox onChange={attachFiles} disabled={lockTime > 0} />
              <ChatInput
                type={'text'}
                maxLength={'1024'}
                width={'100%'}
                value={inputMessage}
                autoComplete={'off'}
                placeholder={t('chat.input-placeholder')}
                onChange={(event) => setInputMessage(event.target.value)}
                fontSize={'1rem'}
                onKeyPress={(event) => event.key === 'Enter' && sendMessage()}
              />
              {lockTime > 0 ? (
                <Timer time={lockTime} fontSize={'1rem'} hint={'chat.send-lock-hint'} />
              ) : (
                <SendButton
                  size={'2.4vh'}
                  hint={t('chat.send-hint')}
                  onClick={sendMessage}
                />
              )}
            </>
          )}
        </Translation>
      </ChatFooter>
    </ChatRoot>
  );
};

const ChatMessageBox = ({
  message,
  alias,
  icons,
  pending,
}: {
  message: ChatMessage;
  alias?: string;
  icons?: Record<string, React.ReactNode>;
  pending?: boolean;
}) => {
  const { t } = useTranslation();
  const [dummy, setDummy] = React.useState(0);

  function onSelectOption(option: string) {
    if (!message.selected && !!message.onSelect) {
      message.selected = option;
      setDummy(dummy + 1);
      message.onSelect(option, message);
    }
  }

  function onMediaMessageClick() {
    message.media?.includes('.pdf') && window.open(message.media, '_blank');
  }

  return (
    <ChatMessageBoxRoot>
      <div className={`message ${message.type}`}>
        {message.type === 'date' ? (
          <div className={'date'}>
            <TextEx size={'1.5vh'} color={'unset'}>
              {formatChatDate(message.date)}
            </TextEx>
          </div>
        ) : (
          <>
            <div className={classNames('text', message.media?.includes('.pdf') && 'pdf')}>
              {message.type === 'system' && (
                <div className={'sender'}>
                  <TextEx size={pageFontSize} color={'unset'}>
                    <Txt k={alias || message.user || ''} />
                  </TextEx>
                </div>
              )}
              {!!message.text &&
              (message.type === 'hint' || message.type === 'select') ? (
                <TextEx size={pageFontSize}>
                  <div dangerouslySetInnerHTML={{ __html: message.text || '' }} />
                </TextEx>
              ) : (
                <TextEx size={pageFontSize}>{message.text}</TextEx>
              )}
              {message.media ? (
                message.media.includes('.pdf') ? (
                  <>
                    <PDFEx src={message.media} width="100%" height="100%" />
                    <a className={'new-tab'} onClick={onMediaMessageClick}>
                      {`${t('chat.open-in-new-tab')}`}
                    </a>
                  </>
                ) : (
                  <IconEx
                    src={
                      message.media.match(/^https?:/)
                        ? message.media
                        : 'https://' + message.media
                    }
                    alt={
                      message.media.match(/^https?:/)
                        ? message.media
                        : 'https://' + message.media
                    }
                    size={'18vh'}
                    top={'0.8vh'}
                    borderRadius={Defaults.borderRadius}
                    onClick={() => openLink(message.media || '')}
                  />
                )
              ) : undefined}
            </div>
            <div className={'time'}>
              <TextEx size={'1.4vh'} color={'unset'}>
                {formatChatTime(message.date)}
              </TextEx>
              {pending && (
                <div className={'pending'}>
                  <PendingIcon size={'1rem'} />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {message.options ? (
        <div className={'options'}>
          {message.options.map((option, i) => (
            <div
              key={option}
              className={[
                'option',
                option !== message.selected && !message.selected && !!message.onSelect
                  ? 'enabled'
                  : '',
                option === message.selected ? 'selected' : '',
              ].join(' ')}
              onClick={() => onSelectOption(option)}>
              {option.endsWith('yes') ? (
                <>
                  <YesIcon size={'2vh'} />
                  &nbsp;&nbsp;
                </>
              ) : undefined}
              {option.endsWith('no') ? (
                <>
                  <NoIcon size={'2vh'} />
                  &nbsp;&nbsp;
                </>
              ) : undefined}
              {!!icons && !!message.icons && !!message.icons[i] ? (
                <>{icons[message.icons[i]]}&nbsp;&nbsp;</>
              ) : undefined}
              {!message.hideCaptions ? (
                <TextEx color={'inherit'} uppercase>
                  {option === 'yes' || option === 'no' ? (
                    <Txt k={`chat.option.${option}`} />
                  ) : (
                    <Txt k={option} />
                  )}
                </TextEx>
              ) : undefined}
            </div>
          ))}
        </div>
      ) : undefined}
    </ChatMessageBoxRoot>
  );
};

export const AttachmentButton = ({
  size,
  hint,
}: {
  size: number | string;
  hint?: string;
}) => (
  <ButtonBox title={hint}>
    <svg style={{ width: size, height: size }} viewBox={'0 0 351 351'}>
      <g>
        <path
          d="M324.572,42.699c-35.419-35.419-92.855-35.419-128.273,0L19.931,219.066c-26.575,26.575-26.575,
                    69.635, 0,96.211 c21.904,21.904,54.942,25.441,80.769,11.224c2.698-0.136,5.351-1.156,
                    7.415-3.197l176.367-176.367 c17.709-17.709,17.709-46.416,0-64.125s-46.416-17.709-64.125,0L76.052,
                    227.116c-4.422,4.422-4.422,11.61, 0,16.031  c4.422,4.422,11.61,4.422,16.031,0L236.388,
                    98.843c8.866-8.866,23.219-8.866,32.063,0c8.866, 8.866,8.866,23.219,0,32.063 L100.088,
                    299.268c-17.709,17.709-46.416,17.709-64.125,0s-17.709-46.416, 0-64.125L212.33,58.73 c26.575-26.575,
                    69.635-26.575,96.211,0c26.575,26.575,26.575,69.635,0,96.211L148.205,315.277c-4.422,4.422-4.422,
                    11.61,0,16.031  c4.422,4.422,11.61,4.422,16.031,0l160.336-160.336C359.991,135.554,359.991,
                    78.118,324.572,42.699z"
        />
      </g>
    </svg>
  </ButtonBox>
);

const SendButton = ({
  size,
  hint,
  onClick,
}: {
  size: number | string;
  hint?: string;
  onClick: () => void;
}) => (
  <ButtonBox title={hint} onClick={onClick} fill={Defaults.mainColor}>
    <svg style={{ width: size, height: size }} viewBox={'0 0 30 30'}>
      <polygon points="28,15 18,15 5.338,12.885 4,11 4,5 6.994,3.266 27.391,14.079" />
      <circle cx="27" cy="15" r="1" />
      <circle cx="6" cy="11" r="2" />
      <circle cx="6" cy="5" r="2" />
      <polygon points="28,15 18,15 5.338,17.115 4,19 4,25 6.994,26.734 27.391,15.921" />
      <circle cx="6" cy="19" r="2" />
      <circle cx="6" cy="25" r="2" />
    </svg>
  </ButtonBox>
);

const YesIcon = ({ size }: { size: number | string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'}>
    <polygon points="211.344,306.703 160,256 128,288 211.414,368 384,176 351.703,144 	" />
    <path
      d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
		    C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,
		    216S375.297,472,256,472z"
    />
  </svg>
);

const NoIcon = ({ size }: { size: number | string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 65 65'}>
    <path
      d="M32.759,0C14.696,0,0,14.695,0,32.759s14.695,32.759,32.759,32.759s32.759-14.695,32.759-32.759S50.822,0,
            32.759,0z  M6,32.759C6,18.004,18.004,6,32.759,6c6.648,0,12.734,2.443,17.419,6.472L12.472,50.178C8.443,
            45.493,6,39.407,6,32.759z  M32.759,59.518c-5.948,0-11.447-1.953-15.895-5.248l37.405-37.405c3.295,4.448,
            5.248,9.947,5.248,15.895 C59.518,47.514,47.514,59.518,32.759,59.518z"
    />
  </svg>
);

const ChatRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)}` : '')}
  ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
    border-radius: ${px(Defaults.borderRadius)};
  background-color: white;
`;
const ChatCaptionRoot = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  height: 7vh;
  padding: 2vh 4vh;
`;
const ChatCaptionText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2vh;
`;
const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 2vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
const ChatMessageBoxRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 2vh;
  word-break: break-word;

  & .message {
    display: flex;
    flex-direction: row;
    border-radius: ${px(Defaults.borderRadius)};
    padding: 5px 10px;
  }

  & .message.date {
    padding: 0 2vh;
    align-self: center;
    align-items: center;
    justify-content: center;
    color: ${Defaults.grayColor};
    background-color: #f1f1f1;
  }

  & .message.user {
    align-self: flex-end;
    background-color: #effafd;
  }

  & .text .new-tab {
    color: ${Defaults.mainColor};
  }

  & .text.pdf {
    height: fit-content;
  }

  & .message.system {
    align-self: flex-start;
    background-color: #f5f5f5;
  }

  & .message.hint,
  & .message.select {
    align-self: flex-start;
    border: 0.1vh solid #e9e9e9;
  }
  & .message.select {
    width: 100%;
  }

  & .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 0;
  }
  & .option {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.6vh;
    border: 0.1vh solid ${Defaults.mainColor};
    border-radius: ${px(Defaults.borderRadius)};
    color: ${Defaults.mainColor};
    fill: ${Defaults.mainColor};
    transition: ${Defaults.transition};
    user-select: none;
  }
  & .option:not(:first-child) {
    margin-left: 2vh;
  }
  & .option svg {
    fill: inherit;
  }
  & .option.enabled:hover,
  .option.selected {
    background-color: ${Defaults.mainColor};
    color: white;
    fill: white;
  }
  & .option.enabled:hover {
    cursor: pointer;
  }

  & div.sender {
    display: flex;
    height: 3vh;
  }

  & .message.user div.sender {
    color: ${Defaults.grayColor};
  }

  & .message.system div.sender {
    color: ${Defaults.mainColor};
  }

  & .text {
    flex-grow: 1;
  }

  & .time {
    display: flex;
    margin-left: 1vw;
    align-items: flex-end;
    min-width: 36px;
    color: ${Defaults.grayColor};
  }

  & .time .pending {
    align-self: center;
    width: 1rem;
  }
`;
const ChatFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 20px;
`;
const ChatInput = styled.input`
  display: flex;
  flex-grow: 1;
  margin-left: 1rem;
  outline: none;
  border: none;
  width: ${(props) => props.width ?? 'unset'};
  font-family: ${Defaults.fontFamily};
  font-size: ${(props) => props.fontSize || px(Defaults.fontSize)};
  &::placeholder {
    color: ${pageInfoCaptionColor};
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2vh;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  & svg {
    fill: ${(props) => props.fill || pageInfoCaptionColor};
    transition: ${Defaults.transition};
  }
  &:hover svg {
    fill: ${(props) => props.fill || Defaults.mainColor};
  }
`;
export const AttachBox = styled.label`
  & input[type='file'] {
    display: none;
  }
`;

function insertDates(messages: ChatMessage[]): ChatMessage[] {
  const messages2: ChatMessage[] = [];
  for (let i = 0; i < messages.length; i++) {
    const message = messages[i];
    const prevMessage = i > 0 ? messages[i - 1] : undefined;
    if (
      prevMessage === undefined ||
      (!!message.date &&
        prevMessage.date &&
        message.date.getDate() !== prevMessage.date.getDate())
    ) {
      messages2.push({ type: 'date', date: copyDate(message.date) });
    }
    messages2.push(message);
  }
  return messages2;
}

function copyDate(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function formatChatDate(date: Date): string {
  let result = `${tt('months', date.getMonth().toString())} ${date.getDate()}`;
  if (date.getFullYear() !== new Date().getFullYear()) {
    result += `, ${date.getFullYear()}`;
  }
  return result;
}

function formatChatTime(date: Date): string {
  return `${lpad(date.getHours())}:${lpad(date.getMinutes())}`;
}

function lpad(v: any): string {
  return v.toString().padStart(2, '0');
}

export default ChatEx;
