import { createBrowserHistory } from 'history';

import { listenNotifications, Notifications } from '../helpers/notifications';
import { escapeHtml, formatString } from '../helpers/utils';
import { onLanguageChange, t } from './i18n';

const history = createBrowserHistory();

const aliases = [
  { pattern: /deals\/(.+)/, page: 'deal' },
  { pattern: /bids\/(new)/, page: 'bid-new' },
  { pattern: /bids\/(.+)/, page: 'bid' },
  { pattern: /users\/(.+)/, page: 'user' },
  { pattern: /wallet\/(.*)/, page: 'wallet' },
];

let unreadNotifications = 0;

onPageChange((page) => setDocumentTitle(page));
onLanguageChange(() => setDocumentTitle(getPage()));
setTimeout(() => setDocumentTitle(getPage()), 100);
listenNotifications(onNotificationsChange);

function setDocumentTitle(page: Page) {
  const title = t(`${page.name}.title`, t('app-title'));
  const notifications = unreadNotifications > 0 ? `(${unreadNotifications}) ` : '';
  document.title =
    notifications + escapeHtml(formatString(title, page.param)) || t('app-title');
}

function parsePath(path: string): Page {
  for (const alias of aliases) {
    const match = path.match(alias.pattern);
    if (match && match.length > 1) {
      return { name: alias.page, param: match[1] };
    }
  }
  return { name: path.substr(1) };
}

function onNotificationsChange(notifications: Notifications) {
  if (unreadNotifications !== notifications.unreadGroups) {
    unreadNotifications = notifications.unreadGroups;
    setDocumentTitle(getPage());
  }
}

export interface Page {
  name: string;
  param?: string;
}

export function getPage(): Page {
  return parsePath(window.location.pathname);
}

export function setPage(page?: string) {
  history.push(`/${page || ''}`);
  window.scroll(0, 0);
}

export function onPageChange(callback: (page: Page) => void) {
  history.listen((location: any) => callback(parsePath(location.pathname)));
}

export default history;
