import React from 'react';
import styled from 'styled-components';

import {
  getLanguage,
  LanguageEnum,
  onLanguageChange,
  setLanguage,
} from '../../config/i18n';
import { Defaults } from '../common';
import IconEx from '../controls/IconEx';
import { PageText } from '../controls/Page';
import { LockIcon } from './Icons';
import { Kind } from './types';

const Header = ({
  kind,
  allowCancel,
  onCancel,
}: {
  kind: Kind;
  allowCancel: boolean;
  onCancel?: () => void;
}) => {
  const [lang, setLang] = React.useState<string>(getLanguage());

  const changeLang = (newLang) => {
    setLanguage(newLang);
    setLang(newLang);
  };

  onLanguageChange(setLang);

  return (
    <HeaderRoot>
      <div className={'left'}>
        <LockIcon size={'3vh'} fill={Defaults.greenColor} />
        <PageText
          k={`${kind}.title`}
          color={Defaults.whiteColor}
          left={'1vh'}
          width={'20h'}
        />
      </div>
      <div className={'right'}>
        {allowCancel && (
          <PageText
            k={`${kind}.cancel`}
            color={Defaults.grayColor}
            onClick={onCancel}
            customStyle={'text-decoration: underline; cursor: pointer;'}
          />
        )}
        <LangButton
          src={'/ico/top/rus.svg'}
          current={lang}
          lang={LanguageEnum.RU}
          onClick={changeLang}
        />
        <LangButton
          src={'/ico/top/usa.svg'}
          current={lang}
          lang={LanguageEnum.EN}
          onClick={changeLang}
        />
        <LangButton
          src={'/ico/top/ua.svg'}
          current={lang}
          lang={LanguageEnum.UA}
          onClick={changeLang}
        />
        <LangButton
          src={'/ico/top/uz.svg'}
          current={lang}
          lang={LanguageEnum.UZ}
          onClick={changeLang}
        />
        <LangButton
          src={'/ico/top/tj.svg'}
          current={lang}
          lang={LanguageEnum.TJ}
          onClick={changeLang}
        />
      </div>
    </HeaderRoot>
  );
};

const LangButton = ({
  src,
  current,
  lang,
  onClick,
}: {
  src: string;
  current;
  lang: string;
  onClick: (lang: string) => void;
}) => (
  <IconEx
    src={src}
    size={'22px'}
    left={'1vh'}
    onClick={() => onClick(lang)}
    customStyle={current !== lang ? 'opacity: 0.5;' : ''}
    hint={lang.toUpperCase()}
  />
);

const HeaderRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.875rem 1.875rem;
  background-color: black;
  @media (max-width: 480px) {
    flex-direction: column;
    text-align: left;
    padding: 0.875rem 0.875rem;
    gap: 16px;
    align-items: center;
  }

  & .left,
  & .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 480px) {
      justify-content: center;
      align-items: center;
    }
  }

  & .center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-grow: 1;
  }
`;

export default Header;
