import React from 'react';

import { Defaults } from '../common';
import ButtonEx, { ButtonExProps } from './ButtonEx';

interface ActionButtonProps extends ButtonExProps {
  swapColors?: boolean;
  buttonColor?: string;
}

const ActionButton = (props: ActionButtonProps) => {
  const buttonColor = props.disabled
    ? Defaults.inactiveColor
    : props.buttonColor || Defaults.mainColor;
  const captionColor = !props.swapColors ? Defaults.whiteColor : 'unset';
  const hoverColor = props.disabled
    ? Defaults.inactiveColor
    : props.hoverColor || '#00b3fb';

  const customStyle = `
        &:hover {
            background-color: ${hoverColor};
            border-color: ${hoverColor};
        }
        ${
          props.swapColors
            ? `
        & span {
            color: ${buttonColor};
        }
        & svg {
            fill: ${buttonColor};
        }
        &:hover span {
            color: white;
        }
        &:hover svg {
            fill: white;
        }`
            : ''
        }
        ${props.customStyle}
    `;

  return (
    <ButtonEx
      {...props}
      captionColor={captionColor}
      backgroundColor={!props.swapColors ? buttonColor : 'unset'}
      borderColor={buttonColor}
      hoverColor={hoverColor}
      customStyle={customStyle}
    />
  );
};

export default ActionButton;
