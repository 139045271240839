import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatString } from '../../../../helpers/utils';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';
import { Broker, Merchant, Sale } from '../../../../utils/types';
import { ClockIcon, HtmlText, Row } from '../../../common';
import { DealAction, DealCancelAction } from '../../../common/panels/controls';
import { PayCard } from '../../../common/payCard/PayCard';
import styles from './styles.module.scss';

interface ISaleProps {
  sale: Sale;
  broker: Broker;
  onConfirmSale: () => Promise<void>;
  onCancelDeal: () => void;
  onRequisiteChange: (value: string) => void;
}

export const SaleMain: FC<ISaleProps> = ({
  sale,
  onConfirmSale,
  broker,
  onCancelDeal,
  onRequisiteChange,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('max-width: 768px');

  const [requisite, setRequisite] = useState('');
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    onRequisiteChange(requisite);
  }, [requisite]);

  const isRequisiteValid = useMemo(() => {
    return RegExp('(?:\\D*\\d){7,}').test(requisite);
  }, [requisite]);

  const confirmCancel = () => onCancelDeal();

  const onConfirmSaleInit = () => {
    setBusy(true);
    onConfirmSale().then(() => setBusy(false));
  };

  const initialized = useMemo(() => {
    return !!sale.deal || !sale.is_approved;
  }, [!!sale.deal, sale.is_approved]);

  if (initialized) {
    return (
      <div className={styles.wait}>
        <ClockIcon size={isMobile ? '40' : '80'} fill={'#00B3FB'} />
        <div className={styles.title}>{`${t('sale.wait.title')}`}</div>
        <div className={styles.hint}>{`${t('sale.wait.hint')}`}</div>
        <div className={styles.icons}>
          <div className={styles.tip}>
            <div className={styles.icon}>✅</div>
            <div className={styles.text}>{`${t('sale.wait.guarantee')}`}</div>
          </div>
          <div className={styles.tip}>
            <div className={styles.icon}>👨🏻‍💻</div>
            <div className={styles.text}>{`${t('sale.wait.support')}`}</div>
          </div>
          <div className={styles.tip}>
            <div className={styles.icon}>🏦</div>
            <div className={styles.text}>{`${t('sale.wait.topup')}`}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <span>
        <HtmlText
          className={styles.headerText}
          k={`sale.perform-payment`}
          args={[sale.amount ?? '', sale.currency?.toUpperCase() ?? '']}
        />
      </span>
      <div className={styles.paymentBlock}>
        <PayCard
          editable
          onSetRequisite={setRequisite}
          broker={broker}
          selfRequisites={false}
          expireIn={600}
          requisitesText={t('sale.requisite.input-3')}
          placeholder={t(
            `sale.requisites-waiting${broker.name.startsWith('СБП') ? '-sbp' : ''}`,
          )}
          hint={t('sale.requisite.validation-rule')}
          requisites={requisite}
        />
        <div className={styles.hints}>
          <div className={styles.paymentId}>
            {`${t('flash-pay.payment-id')}`}
            <span className={styles.id}>{sale.id}</span>
          </div>
          <div className={styles.numeratedHints}>
            {[`hint-1${broker.name.startsWith('СБП') ? '-sbp' : ''}`, 'hint-2'].map(
              (text, index) => (
                <div className={styles.numeratedHint}>
                  <div className={styles.hintNumber}>{index + 1}</div>
                  <div className={styles.hintText}>{`${t(`sale.${text}`)}`}</div>
                </div>
              ),
            )}
          </div>
          <div className={styles.timeHint}>{`${t('sale.requisite.input-4')}`}</div>
          <Row
            className={styles.actions}
            top={'2vh'}
            gap={'16px'}
            customStyle={'@media (max-width: 480px) { justify-content: center; }'}>
            <DealCancelAction
              className={styles.action}
              caption={'sale.action.cancel'}
              onClick={confirmCancel}
              width={'fit-content'}
            />
            <DealAction
              className={styles.action}
              disabled={!isRequisiteValid || busy}
              swapColors
              caption={'sale.action.confirm'}
              onClick={onConfirmSaleInit}
              width={'20vh'}
            />
          </Row>
        </div>
      </div>
    </div>
  );
};
