import { useEffect, useState } from 'react';

import { DataHook } from './types';

interface Options<T> {
  parameters?: unknown[];
  preventOnInit?: boolean;
  initialValue?: T;
}

const useFetch = <T>(
  fetchFunc: (...args: unknown[]) => Promise<T>,
  options?: Options<T>,
): DataHook<T> => {
  const [data, setData] = useState<T | null>(options.initialValue ?? null);
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    setLoading(true);
    fetchFunc
      .apply(fetchFunc, options.parameters)
      .then((res) => setData(res))
      .catch(() => ({}))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    !options.preventOnInit && fetchData();
  }, []);

  return {
    data,
    refetch: fetchData,
    loading,
  };
};

export default useFetch;
