import { useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(`(${query})`);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };

    try {
      media.addEventListener('change', listener);
      return () => media.removeEventListener('change', listener);
    } catch (e1) {
      try {
        media.addListener(listener);
        return () => media.removeListener(listener);
      } catch (e2) {
        console.error(e2);
      }
    }
  }, [matches, query]);

  return matches;
}
