import Cookies from 'universal-cookie';

const cookies = new Cookies();

export function getItem(field: string, defaultValue?) {
  try {
    return cookies.get(field) || defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function setItem(field: string, value: any) {
  try {
    removeItem(field);
    cookies.set(field, value, { path: '/' });
  } catch (e) {
    console.error(e);
  }
}

export function removeItem(field: string) {
  try {
    cookies.remove(field, { path: '/' });
  } catch (e) {
    console.error(e);
  }
}
