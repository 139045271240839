import React from 'react';

import { Defaults } from '../common';
import { controlHeight } from '../common/constants';
import InputEx from '../controls/InputEx';
import { pageBorderColor } from '../controls/Page';

export const PasswordInput = ({
  value,
  onChange,
  error,
}: {
  value?: string;
  onChange: (value: string) => void;
  error: boolean;
}) => (
  <InputEx
    type={'password'}
    value={value}
    onChange={onChange}
    left={'1vh'}
    height={controlHeight}
    width={'35vh'}
    borderColor={error ? Defaults.redColor : pageBorderColor}
  />
);
