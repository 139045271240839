import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { classNames, findObjectInList } from '../../helpers/utils';
import {
  calcSize,
  ComponentRootEx,
  Defaults,
  onClickOutside,
  PopoverEx,
  px,
} from '../common';
import Txt from './Txt';

const defaultFontSize = '1.8vh';

export interface SelectExProps {
  width?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  height?: number | string;
  top?: number | string;
  left?: number | string;
  onChange?: (id, value) => void;
  fontSize?: number | string;
  component?: ReactNode;
  popupComponent?: ReactNode;
  caption?: string;
  className?: string;
  hideArrow?: boolean;
  id?: string;
  options?: SelectExOptionProps[];
  allOptions?: boolean;
  defaultFirst?: boolean;
  optionClassName?: string;
  borderColor?: string;
  hoverColor?: string;
  backgroundColor?: string;
  allowScroll?: boolean;
  borderRadius?: number | string;
}

export interface SelectExOptionProps {
  id: string;
  value?: string;
  caption?: string;
  img?: ReactNode;
  imgPosition?: 'left' | 'right';
  fontSize?: number | string;
  textAlign?: string;
  component?: ReactNode;
}

export default class SelectEx extends React.Component<SelectExProps> {
  state: {
    opened: boolean;
  } = {
    opened: false,
  };

  popoverOpen = (event) => {
    if (this.state.opened) {
      this.popoverClose();
      return;
    }
    const { options } = this.props;
    if (!!options && options.length > 0) {
      onClickOutside(event.currentTarget, this.popoverClose);
      this.setState({ opened: true });
    }
  };

  popoverClose = () => {
    this.setState({ opened: false });
  };

  getSelectedOption(): SelectExOptionProps | undefined {
    const { id, options, defaultFirst } = this.props;
    if (!!id && !!options) {
      const selected = findObjectInList(options, 'id', id);
      if (selected) {
        return selected;
      }
    }
    if (defaultFirst && !!options) {
      return options[0];
    }
  }

  getOptions(selectedId?: string): SelectExOptionProps[] | undefined {
    const { options, allOptions = false } = this.props;
    if (allOptions) {
      return options;
    }
    if (options) {
      return options.filter((option) => selectedId !== option.id);
    }
  }

  render(): React.ReactNode {
    const {
      width,
      minWidth,
      height = Defaults.height,
      maxHeight,
      top,
      left,
      component,
      popupComponent,
      caption,
      hideArrow = false,
      onChange,
      optionClassName,
      className,
      fontSize,
      borderColor,
      hoverColor,
      backgroundColor,
      allowScroll,
      borderRadius,
    } = this.props;
    const selected = this.getSelectedOption();
    const options = this.getOptions(selected ? selected.id : undefined);

    return (
      <SelectRoot
        className={classNames(className, this.state.opened && 'active')}
        width={width}
        minWidth={minWidth}
        height={height}
        top={top}
        left={left}
        onClick={this.popoverOpen}
        borderColor={borderColor}
        hoverColor={hoverColor}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}>
        <div style={{ flexGrow: 1 }}>
          {component ? (
            component
          ) : selected ? (
            <SelectExOption
              option={selected}
              caption={caption}
              className={classNames('selected')}
              borderColor={borderColor}
              borderRadius={borderRadius}
              height={height}
              fontSize={fontSize}
              backgroundColor={backgroundColor}
            />
          ) : undefined}
        </div>
        <Popover
          className={this.state.opened ? 'opened' : ''}
          top={height}
          maxHeight={maxHeight}
          allowScroll={allowScroll}
          borderRadius={borderRadius}>
          {popupComponent
            ? popupComponent
            : options
            ? options.map((option, i) => (
                <SelectExOption
                  key={i}
                  className={optionClassName}
                  option={option}
                  height={height}
                  fontSize={fontSize}
                  borderColor={borderColor}
                  onChange={onChange}
                  backgroundColor={backgroundColor}
                  borderRadius={borderRadius}
                />
              ))
            : undefined}
        </Popover>
        {!hideArrow ? (
          <ArrowContainer>
            <Arrow size={'1.2vh'} fill={Defaults.mainColor} opened={this.state.opened} />
          </ArrowContainer>
        ) : undefined}
      </SelectRoot>
    );
  }
}

const SelectExOption = ({
  option,
  className,
  height,
  caption = option.caption,
  fontSize = option.fontSize,
  textAlign = option.textAlign,
  backgroundColor,
  hoverColor,
  onChange,
  borderRadius,
  borderColor,
}: {
  option: SelectExOptionProps;
  className?: string;
  height: number | string;
  caption?: string;
  fontSize?: number | string;
  textAlign?: string;
  backgroundColor?: string;
  borderColor?: string;
  hoverColor?: string;
  onChange?: (id, value) => void;
  borderRadius?: number | string;
}) => (
  <OptionContainer
    className={className}
    height={height}
    backgroundColor={backgroundColor}
    hoverColor={hoverColor}
    borderColor={borderColor}
    borderRadius={borderRadius}
    onClick={onChange ? () => onChange(option.id, option.value) : undefined}>
    {reverseNodes(
      option.imgPosition === 'right',
      option.img ? <React.Fragment key={1}>{option.img}</React.Fragment> : undefined,
      option.img ? <OptionSpacer key={2} /> : undefined,
      <OptionValueBox key={3} textAlign={textAlign}>
        {caption ? (
          <OptionValueCaption>
            <Txt k={caption} />
          </OptionValueCaption>
        ) : undefined}
        {option.component ? (
          option.component
        ) : (
          <OptionValue fontSize={fontSize} textAlign={textAlign}>
            {option.value !== undefined ? option.value : option.id}
          </OptionValue>
        )}
      </OptionValueBox>,
    )}
  </OptionContainer>
);

const Arrow = ({
  size,
  fill,
  opened,
}: {
  size: number | string;
  fill: string;
  opened: boolean;
}) => (
  <ArrowRoot
    size={size}
    viewBox={'0 0 452 452'}
    fill={fill}
    className={opened ? 'opened' : ''}>
    <path
      d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
    />
  </ArrowRoot>
);

const SelectRoot = styled(ComponentRootEx)`
  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0 0.5vh;
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : undefined}

  ${(props) => (props.borderColor ? `border-color: ${props.borderColor};` : undefined)}

  &.selected {
    border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  }

  &:not(.selected):hover {
    background-color: ${Defaults.hoverColor};
  }

  &:not(.selected):last-child {
    border-bottom-left-radius: ${(props) =>
      px(props.borderRadius || Defaults.borderRadius)};
    border-bottom-right-radius: ${(props) =>
      px(props.borderRadius || Defaults.borderRadius)};
  }

  &:not(.selected) {
    height: ${(props) => px(props.height)};
    border-top: 0.1vh solid ${(props) => props.hoverColor || Defaults.hoverColor};
    ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
  }
`;
const OptionSpacer = styled.div`
  display: flex;
  width: ${px(Defaults.spacing)};
  height: 0.1vh;
`;
const OptionValueBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.4vh;
  overflow: hidden;
  ${(props) => (props.textAlign ? 'width: 100%;' : '')}
`;
const OptionValueBoxItem = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;
const OptionValueCaption = styled(OptionValueBoxItem)`
  font-size: 1.4vh;
  color: ${Defaults.grayColor};
`;
const OptionValue = styled(OptionValueBoxItem)`
  font-size: ${(props) => px(props.fontSize || defaultFontSize)};
  color: black;
  ${(props) => (props.textAlign ? `justify-content: ${props.textAlign};` : '')}
`;
const Popover = styled(PopoverEx)`
  width: calc(100% + ${calcSize(Defaults.borderWidth, (n) => n * 2)} + 1px);
  ${(props) => (props.maxHeight ? `max-height: ${px(props.maxHeight)};` : '')}
  left: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: ${(props) =>
    px(props.borderRadius || Defaults.borderRadius)};
  border-bottom-right-radius: ${(props) =>
    px(props.borderRadius || Defaults.borderRadius)};
  ${(props) => (props.allowScroll ? 'overflow-y: auto;' : '')}
`;
const ArrowContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
    margin-right: 1.2vh;
`;
const ArrowRoot = styled.svg`
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  transition-duration: 0.3s;
  &.opened {
    transform: rotate(180deg);
  }
`;

function reverseNodes(condition: boolean, ...nodes: ReactNode[]): ReactNode[] {
  return condition ? nodes.reverse() : nodes;
}
