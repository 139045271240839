import { api } from '../../utils/api';
import { Commission, Notification } from '../../utils/types';

export function findDealNotification(
  notifications: Notification[],
  dealId: string,
): number | undefined {
  for (const n of notifications) {
    if (
      n.type === 'deal' ||
      n.type === 'cancel_deal' ||
      n.type === 'timeout' ||
      n.type === 'dispute' ||
      n.type === 'closed_dispute'
    ) {
      if (n.details.id === dealId && !n.is_read) {
        return n.id;
      }
    }
  }
  return;
}

export function findTransactionNotification(
  notifications: Notification[],
): [Notification | undefined, string | undefined] {
  for (const notification of notifications) {
    if (notification.type === 'transaction' && notification.details.type === 'out') {
      return [notification, notification.details.link];
    }
  }
  return [undefined, undefined];
}

export function findMessageNotifications(
  notifications: Notification[],
): Record<string, number[]> {
  const messages = {};
  for (const notification of notifications) {
    if (notification.type === 'message' && !notification.is_read) {
      const sender = notification.details.sender;

      const unread = messages[sender] || [];
      unread.push(notification.id);

      messages[sender] = unread;
    }
  }
  return messages;
}

export function readNotifications(notificationIds: number[], cb?: () => void) {
  if (notificationIds.length > 0) {
    api.deal
      .readNotifications(notificationIds)
      .then(() => (cb ? cb() : undefined))
      .catch(() => undefined);
  }
}

export function calcAmountCurrency(
  amountCrypto: number,
  rate: number,
  commission: Commission,
): number {
  const amount =
    (amountCrypto + commission.commission) / (1 - commission.commission_buyer);
  return Math.ceil((amount * rate) / 10) * 10;
}
