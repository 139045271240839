import { getItem, removeItem, setItem } from '../utils/localStorage';

type AuthTokens = Record<string, string>;

const TOKENS_FIELD = 'sky_pay_tokens';
const REFRESH_TOKEN_FIELD = 'sky_pay_refresh_token';
export const SAVED_PAYMENT_EMAIL = 'saved_payment_email';
export const RECAPTCHA = 'recaptcha_token';

function getPaymentId(): string {
  const parts = window.location.pathname.split('/');
  return parts.length > 0 ? parts[parts.length - 1] : '';
}

export function setAuthToken(token?: string) {
  const authTokens = getAuthTokens();
  authTokens[getPaymentId()] = token || '';
  setAuthTokens(authTokens);
}

export function getAuthToken() {
  const authTokens = getAuthTokens();
  return authTokens[getPaymentId()];
}

export function removeAuthToken() {
  setAuthToken(undefined);
}

export function getAuthHeader(token?: string) {
  return {
    Authorization: `Bearer ${token || getAuthToken()}`,
  };
}

let internalAuthTokens: AuthTokens;

function getAuthTokens(): AuthTokens {
  if (!internalAuthTokens) {
    internalAuthTokens = getItem(TOKENS_FIELD) || {};
  }
  return internalAuthTokens;
}

function setAuthTokens(authTokens: AuthTokens) {
  internalAuthTokens = authTokens;
  setItem(TOKENS_FIELD, authTokens);
}

export const getRefreshToken = () => {
  return getItem(REFRESH_TOKEN_FIELD) || '';
};

export const setRefreshToken = (refreshToken?: string) => {
  if (refreshToken) setItem(REFRESH_TOKEN_FIELD, refreshToken);
};

export const removeRefreshToken = () => {
  removeItem(REFRESH_TOKEN_FIELD);
};
