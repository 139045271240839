import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Sale } from '../../../utils/types';
import styles from './styles.module.scss';

interface IOrderDetailsProps {
  sale: Sale;
}

export const OrderDetails: FC<IOrderDetailsProps> = ({ sale }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.orderDetailsContainer}>
      <div className={styles.merchantInfo}>
        <img
          src={sale.merchant.image_url || '/logo.svg'}
          style={!sale.merchant.image_url ? { objectFit: 'scale-down' } : {}}
          alt="logo"
        />
        <div className={styles.textInfo}>
          <div className={styles.text}>
            {`${t('sale.order.merchant')}`}:{' '}
            <div className={styles.highlighted}>{sale.merchant.website}</div>
          </div>
          <div className={styles.amount}>
            {sale.amount}{' '}
            <span className={styles.currency}>
              {sale.is_currency_amount ? sale.currency : sale.symbol}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.orderInfo}>
        {sale.label && (
          <div className={styles.text}>
            {`${t('sale.order.label')}`}:{' '}
            <div className={styles.highlighted}>{sale.label}</div>
          </div>
        )}
        <div className={styles.text}>
          {`${t('sale.order.payment-id')}`}:{' '}
          <div className={styles.highlighted}>{sale.id}</div>
        </div>
        {sale.deal && (
          <div className={styles.text}>
            {`${t('sale.order.order-num')}`}:{' '}
            <div className={styles.highlighted}>{sale.deal.id}</div>
          </div>
        )}
        {sale.deal && (
          <div className={styles.text}>
            {`${t('sale.order.you-send')}`}:{' '}
            <div className={styles.highlighted}>
              {sale.deal.amount} {sale.deal.symbol.toUpperCase()}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
