import { APP_ENVIRONMENT, BTC_URL, ETH_URL, USDT_URL } from '../../helpers/settings';

export const controlHeight = '4.6vh';
export const labelColor = '#808080';
export const actionHoverColor = '#00ed00';

export const supportUser = APP_ENVIRONMENT == 'production' ? 'SKYPAY' : 'xxx';
export const searchBidMaxMinutes = 4;
export const waitPaymentForMinutes = 20;
export const canOpenDisputeTime = (waitPaymentForMinutes - 5) * 60 * 2;
export const timeoutUser = 10000;

export const transactionLinkMasks = {
  BTC: BTC_URL,
  ETH: ETH_URL,
  USDT: USDT_URL,
};
