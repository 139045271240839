import './config/i18n.ts';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';

if (!window['this is IE']) {
  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(<App />);
}
