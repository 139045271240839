import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatString } from '../../../../helpers/utils';
import { FlashPayBroker, PurchaseV2, SaleV2 } from '../../../../utils/types';
import { HtmlText } from '../../../common';
import { PayCard } from '../../../common/payCard/PayCard';
import { FlashPayType } from '../FlashPay';
import styles from '../styles.module.css';

interface FlashPayPaymentProps {
  requisites?: string;
  id?: string;
  broker?: FlashPayBroker;
  deal?: PurchaseV2 | SaleV2;
  type: FlashPayType;
}

export const FlashPayPayment: FC<FlashPayPaymentProps> = ({
  requisites,
  id,
  broker,
  deal,
  type,
}) => {
  const { t } = useTranslation();

  const initialized = useMemo(() => {
    return !!deal.deal || !!deal.autodeal;
  }, [!!deal.deal, !!deal.autodeal]);

  return (
    <div className={styles.paymentContainer}>
      <span>
        {initialized && (
          <HtmlText
            className={styles.headerText}
            k={`flash-pay.${type}.perform-payment`}
            args={[deal.amount ?? '', deal.currency?.toUpperCase() ?? '']}
          />
        )}
        {initialized && type === 'payment' && (
          <HtmlText className={styles.warning} k={'flash-pay.payment.warning'} />
        )}
      </span>
      <div className={styles.paymentBlock}>
        <PayCard
          broker={broker}
          selfRequisites={type === 'sale'}
          requisites={requisites ?? t('flash-pay.requisites-waiting')}
        />
        <div className={styles.hints}>
          <div className={styles.paymentId}>
            {`${t('flash-pay.payment-id')}`}
            <span className={styles.id}>{id}</span>
          </div>
          <div className={styles.numeratedHints}>
            {type === 'payment' &&
              ['hint-1', 'hint-2'].map((text, index) => (
                <div className={styles.numeratedHint}>
                  <div className={styles.hintNumber}>{index + 1}</div>
                  <div className={styles.hintText}>{`${t(`flash-pay.${text}`)}`}</div>
                </div>
              ))}
            {type === 'sale' &&
              ['hint-sale'].map((text) => (
                <div className={styles.numeratedHint}>
                  <div className={styles.hintText}>{`${formatString(
                    t(`flash-pay.${text}`),
                    deal?.amount ?? '',
                    deal?.is_currency_amount
                      ? deal?.currency.toUpperCase() ?? ''
                      : deal?.symbol.toUpperCase() ?? '',
                  )}`}</div>
                </div>
              ))}
          </div>
          <ul className={styles.additionalHints}>
            {initialized &&
              type === 'payment' &&
              ['warning-1', 'warning-2'].map((text) => (
                <li className={styles.warningText}>{`${formatString(
                  t(`flash-pay.${text}`),
                  deal.amount ?? '',
                  deal?.is_currency_amount
                    ? deal?.currency.toUpperCase() ?? ''
                    : deal?.symbol.toUpperCase() ?? '',
                )}`}</li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
