import React, { useEffect, useState } from 'react';

import { t } from '../../../config/i18n';
import { classNames } from '../../../helpers/utils';
import { ClockIcon, Defaults, Row, TextEx } from '../../common';
import styles from './styles.module.scss';

const ProgressiveTimer = ({
  time,
  top,
  left,
  fontSize = '1.4rem',
  hint,
  noIcon = false,
  isNew = false,
  vertical,
  clockSize,
  color,
}: {
  time: number;
  top?;
  left?;
  fontSize?: number | string;
  hint?: string;
  noIcon?: boolean;
  isNew?: boolean;
  vertical?: boolean;
  clockSize?: number | string;
  color?: string;
}) => {
  const [TimerId] = useState('Timer__' + Math.floor(Math.random() * 1000000));
  const [date, setDate] = useState(Math.round(time / 2));

  useEffect(() => {
    const expiresIn = Math.round(Date.now() / 1000 + date);
    sessionStorage.setItem(TimerId, expiresIn.toString());
    return () => sessionStorage.removeItem(TimerId);
  }, []);

  useEffect(() => {
    const Listener = () => {
      const date = sessionStorage.getItem(TimerId);
      if (date) {
        const expiresIn = Math.round(+date - Date.now() / 1000);
        setDate(expiresIn > 0 ? expiresIn : 0);
      }
    };
    window.addEventListener('focus', Listener);
    return () => window.removeEventListener('focus', Listener);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate((prevTime) => {
        !prevTime && sessionStorage.removeItem(TimerId);
        return prevTime ? --prevTime : 0;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const tick = date % 2 === 0;
  const minutes = date ? Math.floor(date / 60) : 0;
  const seconds = date ? date - minutes * 60 : 0;

  if (isNew) {
    return (
      <div className={styles.timerNew}>
        <div className={classNames(styles.timeLeft, vertical && styles.vertical)}>
          <ClockIcon size={clockSize ?? 24} fill={color ?? '#EF3124'} />
          <div className={styles.timer} style={{ color }}>
            {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
            {/* <span className={styles.mSec}>.11</span> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Row top={top} left={left} title={t(hint)} noWrap>
      {!noIcon && <ClockIcon size={'2rem'} fill={Defaults.grayColor} />}
      <TextEx weight={'500'} size={fontSize} textAlign={'right'}>
        {String(minutes).padStart(2, '0')}
      </TextEx>
      <TextEx
        weight={'500'}
        size={fontSize}
        color={tick ? 'transparent' : undefined}
        textAlign={'center'}>
        :
      </TextEx>
      <TextEx weight={'500'} size={fontSize} textAlign={'left'}>
        {String(seconds).padStart(2, '0')}
      </TextEx>
    </Row>
  );
};

export default React.memo(ProgressiveTimer);
