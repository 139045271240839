import React from 'react';
import { RouteComponentProps } from 'react-router';

import { DealState } from '../../../utils/types';
import LandingPage from '../../common/Landing';
import { CryptoPay } from './CryptoPay';

interface State {
  email?: string;
  showRegistrationBox: boolean;
}

export default class CryptoPayLandingPage extends React.Component<
  RouteComponentProps<{ id: string }>
> {
  state: State = {
    showRegistrationBox: false,
  };

  onChangeDealState = (state: DealState, email: string) => {
    this.setState({
      showRegistrationBox: state === 'closed',
      email: state === 'closed' ? email : undefined,
    });
  };

  public render(): React.ReactNode {
    const { history, location, match } = this.props;
    const { email, showRegistrationBox } = this.state;

    return (
      <LandingPage
        kind={'cryptoPay'}
        email={email}
        dealId={match.params.id}
        showRegistrationBox={showRegistrationBox}
        content={
          <CryptoPay
            history={history}
            location={location}
            match={match}
            onChangeDealState={this.onChangeDealState}
          />
        }
      />
    );
  }
}
