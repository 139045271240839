import i18n from 'i18next';
import React from 'react';
import styled from 'styled-components';

import { LANG_FIELD, LanguageEnum, setLanguage } from '../../config/i18n';
import { getTransactionCurrency } from '../../helpers/settings';
import { MobileWidthLimit, onResize, openLink } from '../../helpers/utils';
import { api } from '../../utils/api';
import { Column, Defaults, px, Row, Spacer } from '../common';
import ActionButton from '../controls/ActionButton';
import IconEx from '../controls/IconEx';
import { PageText } from '../controls/Page';
import PurchaseHint from './Hint';
import { Kind } from './types';

interface LandingProps {
  kind: Kind;
  email?: string;
  showRegistrationBox: boolean;
  content: React.ReactNode;
  dealId?: string;
}

export const VIDEO_HINTS_SRC_BY_CURRENCY = {
  purchase: {
    USD: 'dCLm5jfBTTU',
    UAH: 'gUkGFD0Cfcw',
    AZN: 'XCcrJFnEAXA',
    UZS: 'yHAd10vRpAs',
    RUB: 'zeIHBDyf7J0',
    KZT: 'zeIHBDyf7J0',
    TJS: 'zeIHBDyf7J0',
    BYN: 'zeIHBDyf7J0',
  },
  sale: {
    USD: 'YNde79Kg2Sc',
    UAH: 'SNvTB3MaciY',
    AZN: 'zHfp7yISKXI',
    UZS: 'xz0rbOcs_Ys',
    RUB: '_EJySR7MZUI',
    KZT: '_EJySR7MZUI',
    TJS: '_EJySR7MZUI',
    BYN: '_EJySR7MZUI',
  },
};

export const VIDEO_HINTS_SRC_BY_LANG = {
  purchase: {
    ru: 'zeIHBDyf7J0',
    en: 'dCLm5jfBTTU',
    ua: 'gUkGFD0Cfcw',
    uz: 'yHAd10vRpAs',
    tj: 'zeIHBDyf7J0',
  },
  sale: {
    ru: '_EJySR7MZUI',
    en: 'YNde79Kg2Sc',
    ua: 'SNvTB3MaciY',
    uz: 'xz0rbOcs_Ys',
    tj: '_EJySR7MZUI',
  },
};

interface State {
  hints: number[];
  expandedHint?: number;
  showRegistrationBox: boolean;
  minimized: boolean;
  appLang: string;
}

const labelColor = '#808080';
const ORDER_ID_LOCAL_STORAGE = 'order_id';

const createVideoHintUrl = (kind: Kind, appLang) => {
  if (getTransactionCurrency() === 'AZN' && !appLang) {
    return VIDEO_HINTS_SRC_BY_CURRENCY[kind][getTransactionCurrency()];
  }
  if (appLang) {
    return VIDEO_HINTS_SRC_BY_LANG[kind][appLang];
  }
  return VIDEO_HINTS_SRC_BY_LANG[kind][localStorage.getItem(LANG_FIELD)];
};

export default class LandingPage extends React.Component<LandingProps> {
  state: State = {
    hints: [1, 2, 3],
    expandedHint: 0,
    showRegistrationBox: false,
    minimized: true,
    appLang: '',
  };

  private interval;

  componentDidMount(): void {
    const { kind } = this.props;
    onResize(this.onResize);
    this.handleGetDealId(kind);
    this.showVideoHint(kind);

    window.addEventListener('storage', this.onStorage);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.onStorage);
  }

  onStorage = () => {
    this.setState({ appLang: i18n.language });
  };

  handleGetDealId = (dealType: Kind) => {
    const { dealId } = this.props;
    const apiPath = `${dealType === 'cryptoPay' ? 'cpayment' : dealType + 's'}`;

    api[apiPath].get(dealId).then(({ lang }) => {
      this.changeOrderLanguage(lang, dealId);
    });
  };

  changeOrderLanguage = (lang: LanguageEnum, dealId: string) => {
    if (localStorage.getItem(ORDER_ID_LOCAL_STORAGE) !== dealId) {
      setLanguage(lang, false);
      localStorage.setItem(ORDER_ID_LOCAL_STORAGE, dealId);
    }
  };

  showVideoHint = (dealType: Kind) => {
    if (dealType !== 'cryptoPay') {
      this.interval = setInterval(() => {
        if (getTransactionCurrency()) {
          clearInterval(this.interval);
          this.setState({
            hints: [0, 1, 2, 3],
          });
        }
      }, 500);
    }
  };

  onResize = () => this.setState({ minimized: window.innerWidth <= MobileWidthLimit });

  clickHint = (hint: number) => {
    const { expandedHint } = this.state;
    this.setState({ expandedHint: hint !== expandedHint ? hint : undefined });
  };

  completeRegistration = () => {
    const { email } = this.props;
    if (email) {
      openLink(`https://sky-crypto.com/?email=${encodeURIComponent(email)}`);
    }
  };

  public render(): React.ReactNode {
    const { kind, content } = this.props;
    const { hints, expandedHint, showRegistrationBox, appLang } = this.state;

    return (
      <LandingRoot>
        <div className={'center'}>
          {content}
          <Column padding={kind !== 'cryptoPay' ? '3vh 0' : ''}>
            {showRegistrationBox ? (
              <>
                <RegistrationBox width={'82vh'} onConfirm={this.completeRegistration} />
                <Spacer height={'3vh'} />
              </>
            ) : undefined}
          </Column>
        </div>
        <div className={`side ${kind}`}>
          {kind !== 'cryptoPay' && (
            <Hints
              kind={kind}
              hints={hints}
              expandedHint={expandedHint}
              onClick={this.clickHint}
              appLang={appLang}
            />
          )}
          {showRegistrationBox ? (
            <RegistrationBox onConfirm={this.completeRegistration} bottom />
          ) : undefined}
        </div>
      </LandingRoot>
    );
  }
}

const Hints = ({
  kind,
  hints,
  expandedHint,
  appLang,
  onClick,
}: {
  kind: Kind;
  hints: number[];
  expandedHint?: number;
  appLang: string;
  onClick: (hint: number) => void;
}) => (
  <Column>
    {hints.map((hint, n) => (
      <PurchaseHint
        caption={`${kind}.hints.${hint}.caption`}
        key={n}
        description={`${kind}.hints.${hint}.description`}
        src={`https://www.youtube.com/embed/${createVideoHintUrl(kind, appLang)}`}
        expand={hint === expandedHint}
        onClick={() => onClick(hint)}
      />
    ))}
  </Column>
);

const RegistrationBox = ({
  bottom,
  width,
  onConfirm,
}: {
  bottom?: boolean;
  width?: string;
  onConfirm: () => void;
}) => (
  <RegistrationRoot className={bottom ? 'bottom' : undefined} width={width}>
    <Row>
      <div className={'logo-box'}>
        <IconEx src={'/logo-white.png'} size={'2.6vh'} left={'0.2vh'} />
      </div>
      <Column left={'2vh'}>
        <PageText k={'purchase.registration.caption'} size={'1.6vh'} />
        <PageText
          k={'purchase.registration.hint'}
          color={labelColor}
          size={'1.6vh'}
          top={'3vh'}
        />
        <ActionButton
          caption={'purchase.registration.confirm'}
          fontSize={'1.6vh'}
          height={'3.4vh'}
          top={'3vh'}
          customStyle={'border-radius: 2vh;'}
          buttonColor={Defaults.blackColor}
          hoverColor={'#515151'}
          onClick={onConfirm}
        />
      </Column>
    </Row>
  </RegistrationRoot>
);

const LandingRoot = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-left: auto;
  margin-right: auto;

  & .side.purchase,
  & .side.sale {
    flex: 0 0 40vh;
    padding: 0 3vh;
    height: 100%;
  }

  & .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }

  @media (max-width: 1024px) {
    display: grid;
    grid-column-template: 1fr;

    & .side.purchase,
    & .side.sale {
      width: 100vw;
    }
  }
`;
const RegistrationRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => px(props.width || '38vh')};
  padding: 2vh;
  border-radius: ${px(Defaults.borderRadius)};
  box-shadow: 0.2vh 0.2vh 3vh rgba(0, 0, 0, 0.1);
  background-color: white;

  &.bottom {
    position: absolute;
    bottom: 4vh;
  }

  & .logo-box {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: start;
    flex-shrink: 0;
    background-color: ${Defaults.mainColor};
    border-radius: 50%;
    width: 4vh;
    height: 4vh;
  }
`;
