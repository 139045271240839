import React from 'react';
import InputMask from 'react-input-mask';

const InputMaskComponent = ({
  mask,
  maskType,
  value,
  Children,
  disallowSpace = true,
  errorMessage,
  valueIsValid,
  setValueIsValid,
  validation,
  onChange,
  onKeyPress,
  ...restProps
}: {
  mask?: string;
  maskType?: string;
  value: string | number;
  Children?: any;
  style?: Record<string, unknown>;
  className?: string;
  disabled?: boolean;
  disallowSpace?: boolean;
  valueIsValid?: boolean;
  errorMessage?: string;
  setValueIsValid?: (isValid: boolean) => void;
  validation?: (value: string | number) => boolean;
  onChange: (event: any) => void;
  onKeyPress?: (event: any) => void;
}) => {
  const Input = Children || InputMask;

  return (
    <div>
      {/* TODO: label for input */}
      <InputMask
        mask={mask!}
        value={value || ''}
        onChange={(e) => {
          onChange(e);
        }}
        {...restProps}>
        <Input
          onKeyPress={(e) => {
            onKeyPress && onKeyPress(e);
            disallowSpace && e.key === '_' && e.preventDefault();
          }}
        />
      </InputMask>
    </div>
  );
};

export default React.memo(InputMaskComponent);
