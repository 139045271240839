import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { formatString } from '../../../../helpers/utils';
import { PurchaseV2, SaleV2 } from '../../../../utils/types';
import { BigNotOkIcon, BigOkIcon } from '../../../common/Icons';
import { FlashPayType } from '../FlashPay';
import styles from '../styles.module.css';

interface FlashPayProcessedProps {
  id: string;
  isOk?: boolean;
  deal?: PurchaseV2 | SaleV2;
  type: FlashPayType;
}

export const FlashPayProcessed: FC<FlashPayProcessedProps> = ({
  id,
  isOk,
  deal,
  type,
}) => {
  const { t } = useTranslation();

  const initialized = useMemo(() => {
    return !!deal.deal || !!deal.autodeal;
  }, [!!deal.deal, !!deal.autodeal]);

  const fullInfo = useMemo(() => {
    return initialized && !!deal.amount && !!deal.currency;
  }, [initialized, deal.amount, deal.currency]);

  return (
    <div className={styles.processedContainer}>
      {isOk ? <BigOkIcon /> : <BigNotOkIcon />}
      <span className={styles.paymentText}>
        {fullInfo
          ? `${formatString(
              t(`flash-pay.${type}.${isOk ? 'performed' : 'not-performed'}`),
              deal.amount,
              deal.is_currency_amount
                ? deal.currency?.toUpperCase()
                : deal.symbol?.toUpperCase(),
            )}`
          : `${formatString(t(`flash-pay.${type}.not-performed-clear`))}`}
      </span>
      <div className={styles.paymentId}>
        {`${t('flash-pay.payment-id')}`}
        <span className={styles.id}>{id}</span>
      </div>
    </div>
  );
};
