import { useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { LanguageEnum } from '../../../config/i18n';
import {
  getAuthToken,
  removeAuthToken,
  SAVED_PAYMENT_EMAIL,
  setAuthToken,
  setRefreshToken,
} from '../../../helpers/authHeader';
import { showMessage } from '../../../helpers/notifications';
import { CAPTCHA_SITE_KEY_V2 } from '../../../helpers/settings';
import { classNames, findObjectInList, urlToOpen } from '../../../helpers/utils';
import { useNotifications } from '../../../hooks/useChat';
import { api, updater } from '../../../utils/api';
import { FetchError, isJson } from '../../../utils/fetchApi';
import { getItem, setItem } from '../../../utils/localStorage';
import { Broker, Commission, Deal, DEAL_SALE, DealBid, Sale } from '../../../utils/types';
import { RestartPanel } from '../../blocks/RestartPanel';
import { isTemporaryUser, UNEXPECTED_ERROR } from '../../common';
import { searchBidMaxMinutes } from '../../common/constants';
import { TelegramIcon, YouTubeIcon } from '../../common/Icons';
import {
  VIDEO_HINTS_SRC_BY_CURRENCY,
  VIDEO_HINTS_SRC_BY_LANG,
} from '../../common/Landing';
import { LanguageSelector } from '../../common/languageSelector/LanguageSelector';
import { DealAction } from '../../common/panels/controls';
import { SaleInit } from './init';
import { SaleMain } from './payment';
import { SaleProcessed } from './processed';
import styles from './styles.module.scss';

type SaleStep = 'init' | 'payment' | 'processed' | 'restart';

const mainDealStates = ['paid', 'closed', 'deleted', 'confirmed'];

interface PaymentParams {
  id: string;
}

export enum SALE_STATUSES {
  CREATED,
  IN_PROCESS,
  OK,
  NOT_OK,
}

const getStep = (sale: Sale, brokerId?: string) => {
  if (!sale || (sale.status === SALE_STATUSES.CREATED && !brokerId)) {
    return 'init';
  }

  if (sale?.status === SALE_STATUSES.IN_PROCESS && !brokerId && !sale?.deal) {
    return 'init';
  }

  if (
    (sale.status === SALE_STATUSES.IN_PROCESS &&
      !(mainDealStates.includes(sale.deal?.state) || sale.deal?.dispute)) ||
    ([SALE_STATUSES.IN_PROCESS, SALE_STATUSES.CREATED].includes(sale?.status) &&
      !sale?.deal &&
      brokerId)
  ) {
    return 'payment';
  }

  if (
    (sale.status === SALE_STATUSES.IN_PROCESS &&
      (mainDealStates.includes(sale.deal.state) || sale.deal.dispute)) ||
    sale.cancel_reason ||
    sale.status > SALE_STATUSES.IN_PROCESS
  ) {
    return 'processed';
  }
};

let stopWatchPayment = () => void 0;

const SalePage = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<PaymentParams>();

  const [deal, setDeal] = useState<Deal>();
  const [sale, setSale] = useState<Sale>();

  const [amountCurrency, setAmountCurrency] = useState<number>();
  const [amountCrypto, setAmountCrypto] = useState<number>();
  const [requisite, setRequisite] = useState('');

  const [broker, setBroker] = useState<Broker>();
  const [brokers, setBrokers] = useState<Broker[]>([]);

  const [bannedBids, setBannedBids] = useState<string[]>([]);
  const [recaptcha, setRecaptcha] = useState<string>('');

  const [showRestart, setShowRestart] = useState(false);
  const [findBidStartDate, setFindBidStartDate] = useState<Date>();

  const recaptchaRef = useRef<any>(null);

  useEffect(() => {
    sale && getBrokers();
  }, [sale]);

  const step = useMemo(() => getStep(sale, broker?.id), [sale, broker?.id]);

  const onSetBroker = (broker: Broker) => {
    !sale.broker_id && setBroker(broker);
  };

  useEffect(() => {
    const stopWatchPaymentStatus = updater(getSaleInfo);
    stopWatchPayment = stopWatchPaymentStatus;
    return () => stopWatchPaymentStatus();
  }, []);

  useEffect(() => {
    bannedBids.length && startSale();
  }, [bannedBids.length]);

  const startDeal = async (bid: DealBid) => {
    if (!bid.id) {
      setShowRestart(true);
      return;
    }
    const newDeal = await api.deal.new(
      DEAL_SALE,
      bid.id,
      sale.is_currency_amount ? sale.amount : undefined,
      !sale.is_currency_amount ? sale.amount : undefined,
      undefined,
      id,
    );
    await api.deal.setRequisite(newDeal.id, requisite || '');
    setDeal(newDeal);
    getSaleInfo();
  };

  const startSale = async (restarted?: boolean) => {
    const start = async () => {
      const bid = await api.sales.findBid(
        broker.id,
        sale.symbol.toLowerCase(),
        bannedBids,
        amountCrypto,
        amountCurrency,
        sale.currency.toLowerCase(),
      );
      await startDeal(bid);
    };

    if (!findBidStartDate || restarted) {
      setShowRestart(false);
      setFindBidStartDate(new Date());
    } else if (
      new Date().getTime() - findBidStartDate.getTime() >
      searchBidMaxMinutes * 60 * 1000
    ) {
      setFindBidStartDate(undefined);
      setShowRestart(true);
      return;
    }

    const token = getAuthToken();
    token ? await start() : await registerTemporaryUser(start);
  };

  const showCaptcha = async () => {
    if (recaptcha) return recaptcha;
    if (recaptchaRef.current) {
      try {
        const captchaToken = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        setRecaptcha(captchaToken);
        return captchaToken;
      } catch (error) {
        return showCaptcha();
      }
    }
  };

  const registerTemporaryUser = async (cb: () => Promise<any>) => {
    const captcha = recaptcha || (await showCaptcha());
    if (captcha && sale.email) {
      try {
        const temporary = await api.auth.registerTemporary(sale.email, captcha, true);
        const { access, refresh } = temporary;
        if (access) {
          setAuthToken(access);
          refresh && setRefreshToken(refresh);
          if (isTemporaryUser(access)) {
            await api.sales.setToken(id, access);
            await cb();
          }
        }
      } catch (e) {
        if (e.response) {
          if (isJson(e.response)) {
            e.response.json().then((result) => {
              showMessage('error', result.detail || e.response.statusText);
            });
          } else {
            showMessage('error', e.response.statusText);
          }
        }
      }
    } else {
      registerTemporaryUser(cb);
    }
  };

  const getBrokers = () => {
    if (sale && sale.deal) return;
    if (broker) return;

    api.purchases
      .getBrokers(sale?.currency.toLowerCase(), 'sky-sale', sale?.symbol.toLowerCase())
      .then((brokers) => {
        setBrokers(brokers);
        if (sale.broker_id) {
          setBroker(brokers.find((broker) => broker.id === sale.broker_id));
        }
      })
      .catch(() => undefined);
  };

  const getSaleInfo = (cb?: () => void) => {
    api.sales
      .get(id)
      .then((sale) => {
        if (sale.token) {
          setAuthToken(sale.token);
        }

        setSale(sale);

        setAmountCrypto(!sale.is_currency_amount ? sale.amount : undefined);
        setAmountCurrency(sale.is_currency_amount ? sale.amount : undefined);

        setDeal(sale.deal);
        !broker && sale.deal && setBroker(sale.deal.lot.broker);
        !sale.is_approved && setTimeout(getSaleInfo, 2000);
      })
      .then(cb)
      .catch(handleUnauthorized);
  };

  const onDealNotification = () => {
    getSaleInfo();
  };

  const onNotification = (notification) => {
    if (notification.type === 'deal') {
      getSaleInfo();
    }
    if (['cancel_deal', 'timeout'].includes(notification.type)) {
      api.deal.get(notification.details.id).then((updatedDeal) => {
        if (updatedDeal.state === 'deleted') {
          if (updatedDeal?.lot.id) {
            setBannedBids((prev) => [...prev, updatedDeal.lot.id]);
          }
        }
      });
    }
  };

  const notifications = useNotifications({
    deal: deal,
    type: 'sale',
    defaultTab: 'info',
    onDealNotification: onDealNotification,
    handlers: { notification: (notification) => onNotification(notification) },
  });

  useEffect(() => {
    notifications.WSConnected && stopWatchPayment();
  }, [notifications.WSConnected]);

  const handleUnauthorized = (e: FetchError) => {
    if ((e.response && e.response.status === 401) || e.message === 'too many attempts') {
      removeAuthToken();
    }
  };

  const onConfirmSale = async () => {
    await startSale(false);
  };

  const onCancelSale = () => {
    setBroker(undefined);
  };

  const restartSale = () => {
    setBroker(undefined);
    setShowRestart(false);
    setFindBidStartDate(undefined);
    getSaleInfo();
  };

  const onSendCoins = async () => {
    await api.deal.run(deal.id);
    getSaleInfo();
    return;
  };

  const steps: Record<SaleStep, JSX.Element> = {
    init: <SaleInit brokers={brokers} sale={sale} onBrokerSelect={onSetBroker} />,
    restart: (
      <RestartPanel
        pm={broker}
        deal={sale}
        onRestart={restartSale}
        type={'sale'}
        onCancel={() => {
          startSale(true);
        }}
      />
    ),
    payment: (
      <SaleMain
        sale={sale}
        broker={broker}
        onRequisiteChange={setRequisite}
        onConfirmSale={onConfirmSale}
        onCancelDeal={onCancelSale}
      />
    ),
    processed: (
      <SaleProcessed
        notifications={notifications}
        sale={sale}
        onSendCoins={onSendCoins}
      />
    ),
  };

  const dealFooter = (() => {
    if (
      ([0, 1].includes(sale?.status) &&
        !['paid', 'closed', 'deleted'].includes(sale?.deal?.state)) ||
      (sale?.deal?.state === 'confirmed' && sale?.deal?.requisite)
    ) {
      return (
        <div className={styles.footer}>
          <a
            target="_blank"
            className={styles.block}
            href={`https://www.youtube.com/embed/${
              ['azn'].includes(sale?.currency)
                ? VIDEO_HINTS_SRC_BY_CURRENCY.sale[sale.currency.toUpperCase()]
                : VIDEO_HINTS_SRC_BY_LANG.sale[i18n.language]
            }`}
            rel="noreferrer">
            <YouTubeIcon
              color={'#0000003D'}
              className={classNames(styles.icon, styles.youtube)}
            />
            <span>{`${t('sale.how-to-sale')}`}</span>
          </a>
          <a href="https://t.me/sky_pay_support" className={styles.block}>
            <TelegramIcon
              color={'#0000003D'}
              className={classNames(styles.icon, styles.tg)}
            />
            <span>{`${t('flash-pay.support')}`}</span>
          </a>
        </div>
      );
    }
  })();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img src={'/ico/logos/sky_pay.svg'} alt={'logo'} className={styles.logo} />
        <div className={styles.title}>{t('sale.title').toUpperCase()}</div>
        <div className={styles.lang}>
          <LanguageSelector noBorder lang={sale?.lang ?? LanguageEnum.RU} />
        </div>
      </div>

      <div className={styles.stepsContainer}>
        {sale && showRestart ? steps['restart'] : steps[step]}
        {dealFooter}
      </div>

      <div>
        <form>
          <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY_V2} size="invisible" ref={recaptchaRef} />
        </form>
      </div>
    </div>
  );
};

export { SalePage };
