import React, { ReactNode } from 'react';
import { Translation } from 'react-i18next';

import { formatString } from '../../helpers/utils';

export default ({ k, args }: { k: string; args?: any[] }) => (
  <Translation>
    {(t) => (args ? formatString(t(k), ...args) : t(k)) as ReactNode}
  </Translation>
);
