import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';

import { api, updater } from '../../../utils/api';
import { CPayment, DealState } from '../../../utils/types';
import Header from '../../common/Header';
import { SkyCryptoLogo } from '../../common/Icons';
import Txt from '../../controls/Txt';
import { LoadingWrapper } from '../../universalComponents/LoadingWrapper';
import { Steps, StepsProps } from '../../universalComponents/Steps';
import { CryptoPayInit } from './init';
import { CryptoPayInProgress } from './inProcess';
import styles from './styles.module.css';

interface CryptoPayParams {
  id: string;
}

interface CryptoPayProps extends RouteComponentProps<{ id: string }> {
  onChangeDealState?: (state: DealState, email: string) => void;
}

type StepIds = 'init' | 'inProcess';

export const CryptoPay = (props: CryptoPayProps): JSX.Element => {
  const { id } = useParams<CryptoPayParams>();
  const [currentStep, setCurrentStep] = useState<StepIds>('init');
  const [cPayment, setCPayment] = useState<CPayment>();

  useEffect(() => {
    getPaymentInfo();
    const stopWatchPaymentStatus = updater(getPaymentInfo, 20000);
    return () => stopWatchPaymentStatus();
  }, []);

  const getPaymentInfo = () => {
    api.cpayment.get(id).then(setCPayment);
  };

  useEffect(() => {
    setCurrentStep(cPayment?.status ? 'inProcess' : 'init');
  }, [cPayment]);

  const steps: StepsProps['steps'] = [
    {
      id: 'init',
      content: cPayment && (
        <CryptoPayInit onUpdate={getPaymentInfo} payment={cPayment} id={id} />
      ),
    },
    {
      id: 'inProcess',
      content: cPayment && <CryptoPayInProgress payment={cPayment} id={id} />,
    },
  ];

  const generateStepHeader = () => {
    return (
      <>
        <Header kind={'purchase'} allowCancel={false} />
        {(!!cPayment.amount || !!cPayment.amount_left) && (
          <span className={styles.amountContainer}>
            <span className={styles.amountValue}>
              {cPayment.amount}
              <span className={styles.amountSymbol}>{cPayment.symbol}</span>
            </span>
            <span className={styles.amountTip}>
              {<Txt k={`crypto-pay.deal-amount`} />}
            </span>
          </span>
        )}
      </>
    );
  };

  return (
    <div className={styles.cryptoPay}>
      <LoadingWrapper isLoading={!cPayment}>
        <div className={styles.container}>
          <SkyCryptoLogo />
          <Steps
            header={generateStepHeader}
            currentStep={currentStep}
            steps={steps}
            gap={'unset'}
          />
        </div>
      </LoadingWrapper>
    </div>
  );
};
