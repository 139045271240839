import { getItem, setItem } from '../utils/localStorage';

export const APP_VERSION = 'v1.6.34';

export const API_URL = process.env.REACT_APP_API_URL;
export const PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
export const CAPTCHA_SITE_KEY_V3 = process.env.REACT_APP_RECAPTCHA_KEY_V3;
export const CAPTCHA_SITE_KEY_V2 = process.env.REACT_APP_RECAPTCHA_KEY_V2;
export const BTC_URL = process.env.REACT_APP_BTC_URL;
export const ETH_URL = process.env.REACT_APP_ETH_URL;
export const USDT_URL = process.env.REACT_APP_USDT_URL;
export const APP_ENVIRONMENT = process.env.REACT_APP_ENV;

const USER_EMAIL = 'user_email';
const USER_CURRENCY = 'user_currency';
export const CURRENT_TRANSACTION_CURRENCY = 'current_transaction_currency';
export const CURRENT_TRANSACTION_LANG = 'currenct_transaction_lang';
const REQUIRED_LINK = 'required_link';

export const ANY_RF_BANK =
  process.env.REACT_APP_ENV === 'production'
    ? '0d1533ae-0a4b-4d04-bc8c-fbc52a6e3483'
    : '1b53e11d-791a-4e83-b355-aceb27a1210f';

export const SBP_BANK =
  process.env.REACT_APP_ENV === 'production'
    ? '25b36f97-8e5b-4ff3-81fc-c2cf567f8f6a'
    : 'efc65f1a-484a-4297-b192-3cf199e38e52';

export function getUserCurrency(): string {
  return getItem(USER_CURRENCY) || 'RUB';
}

export function getTransactionCurrency(): string {
  return getItem(CURRENT_TRANSACTION_CURRENCY);
}

export function getUserEmail(): string {
  return getItem(USER_EMAIL);
}

export function setUserEmail(email: string) {
  setItem(USER_EMAIL, email);
}

export function getRequiredLink(): string {
  return getItem(REQUIRED_LINK);
}

export function setRequiredLink(link: string) {
  setItem(REQUIRED_LINK, link);
}

export function setTransactionCurrency(currency: string) {
  setItem(CURRENT_TRANSACTION_CURRENCY, currency);
}

export function parseJwt(token: string): any {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}
