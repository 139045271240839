import { Md5 } from 'ts-md5';

import { setPage } from '../config/history';
import { t } from '../config/i18n';
import {
  getAuthHeader,
  getRefreshToken,
  removeAuthToken,
  removeRefreshToken,
  setAuthToken,
  setRefreshToken,
} from '../helpers/authHeader';
import { showMessage } from '../helpers/notifications';
import { API_URL, PUBLIC_API_URL } from '../helpers/settings';

export const API_V1 = API_URL + '/rest/v1';
export const API_V2 = PUBLIC_API_URL + '/rest/v2';

//  https://app.atlantsoftware.com/t/SK-VLpxOV1LFZ
// 'Wrong deal status' - we have this error during performing flow on purchase
// 'Mask filled' - if the internet is slow, we get this error when we double click on mask confirmation.
// 'Cannot cancel paid deal' - if the Internet is bad, cancel the transaction after filling out the mask
// 'Cannot cancel processed deal' - in case of bad internet, cancellation of the transaction after cancellation
const ignoreErrorMessage = [
  'Wrong deal status',
  'Mask filled',
  'Cannot cancel paid deal',
  'Cannot cancel processed deal',
  'limit of this lot exceeded',
  'Duplicated deal',
];

let codeData:
  | {
      [key: string]: string;
    }
  | undefined;
let secretKey: string | undefined;

export const SWAGGER_ERROR_REGEXP = /^'(.*)' (.*) - '(.*)'/;
export const SWAGGER_ERROR_SIGNATURE_INDEX = 2;
export const SWAGGER_ERROR_FIELD_INDEX = 3;

interface FetchApiConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: Record<string, any>;
  body?: Record<string, any>;
  headers?: Record<string, string>;
  formData?: FormData;
  suppressError?: boolean;
  noKey?: boolean;
}

let refreshInProcess = false;

export class FetchError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    Object.setPrototypeOf(this, FetchError.prototype);
    this.response = response;
  }

  public text = (): Promise<string> => this.response.text();
}

export async function fetchApi<T = any>(
  api,
  url: string,
  { method = 'GET', params, body, headers, formData, ...config }: FetchApiConfig,
): Promise<T> {
  if (!headers) {
    headers = {};
  }
  if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
    headers['Content-Type'] = 'application/json;charset=utf-8';
  }
  if (headers['Content-Type'] === '') {
    delete headers['Content-Type'];
  }

  const fetchConfig = {
    method,
    body: formData ? formData : body ? JSON.stringify(body) : undefined,
    headers: new Headers(headers),
    mode: 'cors' as any,
    ...config,
  };

  return fetchData(`${api}${url}`, fetchConfig, params || {});
}

export function isJson(response): boolean {
  let contentType = response.headers.get('content-type');
  contentType = contentType.toLowerCase();
  return (
    contentType &&
    (contentType.indexOf('application/json') !== -1 ||
      contentType.indexOf('application/problem+json') !== -1)
  );
}

function getCodeData(): Promise<any> {
  return fetchApi(API_V1, '/codedata', {
    headers: getAuthHeader(),
    suppressError: true,
    noKey: true,
  });
}

async function fetchData(
  url: string,
  fetchConfig,
  params: Record<string, any>,
  attempt = 0,
): Promise<any> {
  if (attempt > 5) {
    throw new Error('too many attempts');
  }

  const urlParams: string[] = [];
  for (const name in params) {
    params[name] && urlParams.push(name + '=' + encodeURIComponent(params[name]));
  }

  if (!fetchConfig.noKey) {
    if (!codeData) {
      codeData = await getCodeData();
    }
    fetchConfig.headers.append('AuthKey', generateSecretKey());
  }

  const fullUrl = urlParams.length > 0 ? url + '?' + urlParams.join('&') : url;
  return fetch(fullUrl, fetchConfig)
    .then(async (response: Response) => {
      if (response.ok) {
        if (isJson(response)) {
          return response.json();
        } else if (isBlob(response)) {
          return response.blob();
        }
        return response.text();
      }
      if (response.status === 412) {
        codeData = undefined;
        secretKey = undefined;
        return fetchData(url, fetchConfig, params, attempt + 1);
      }
      if (response.status === 404) {
        setPage();
      }

      if (response.status === 401) {
        const refreshToken = getRefreshToken();
        if (refreshToken && !refreshInProcess) {
          removeRefreshToken();
          refreshInProcess = true;
          const { access, refresh } = await fetchApi(API_V1, '/auth/refresh', {
            method: 'POST',
            body: { token: refreshToken },
          });
          refreshInProcess = false;
          if (access && refresh) {
            setAuthToken(access);
            setRefreshToken(refresh);
            codeData = undefined;
            secretKey = undefined;
            return fetchData(
              url,
              {
                ...fetchConfig,
                headers: getAuthHeader(access),
              },
              {},
              attempt + 1,
            );
          } else removeAuthToken();
        }
      }

      throw new FetchError(response);
    })
    .catch(async (e: FetchError) => {
      if (!e.response) {
        codeData = undefined;
        secretKey = undefined;
        await sleep(1000);
        return fetchData(url, fetchConfig, params, attempt + 1);
      }
      if (e.response.status === 401) {
        throw e;
      }
      if (
        !fetchConfig.suppressError &&
        !!e.response &&
        !ignoreErrorMessage.includes(e.response.statusText)
      ) {
        if (isJson(e.response)) {
          const handler = async (result) => {
            const [ok, text, redirectUrl] = isRedirectError(result.detail);

            if (
              url.includes('fast-deal-start') &&
              result.detail === 'Bad payment amount'
            ) {
              if (attempt > 10) {
                return showMessage(
                  'error',
                  t('purchase.deal.recreate-deal-error-message'),
                );
              }
              await sleep(2000);
              return fetchData(url, fetchConfig, params, attempt + 1);
            }

            if (ok) {
              return showMessage('redirect', `errors.${text}`, {
                redirectUrl,
                pageOnCancel: 'payment-error',
              });
            }

            !ignoreErrorMessage.includes(result.detail) &&
              showMessage('error', result.detail || e.response.statusText);
          };
          if (e.response['detail'] || e.response['statusText']) {
            handler(e.response);
          } else {
            e.response.clone().json().then(handler);
          }
        } else {
          showMessage('error', e.response.statusText);
        }
      }
      throw e;
    });
}

function isBlob(response): boolean {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.indexOf('text/csv') !== -1;
}

const PAYMENT_ALREADY_EXISTS = 'Payment already exists';

function isRedirectError(error: string): any[] {
  if (error.startsWith(PAYMENT_ALREADY_EXISTS.concat(':'))) {
    return [
      true,
      PAYMENT_ALREADY_EXISTS,
      error.substring(PAYMENT_ALREADY_EXISTS.length + 1).trim(),
    ];
  }
  return [false];
}

function generateSecretKey(): string {
  if (secretKey) {
    return secretKey;
  }
  if (!codeData) {
    return '';
  }

  const needReverse = codeData['aKM'] === 'e';
  const md5 = new Md5();
  let codedataKeys = Object.keys(codeData).sort();
  needReverse && (codedataKeys = codedataKeys.reverse());
  const codedataValues = codedataKeys.map((key) => codeData[key]);
  const result = codedataValues.join('') + 'l';
  secretKey = md5.appendStr(result).end() as string;
  return secretKey;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
