import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames, urlToOpen } from '../../../../helpers/utils';
import { INotificationsAggregator } from '../../../../hooks/useChat';
import { api } from '../../../../utils/api';
import { Purchase } from '../../../../utils/types';
import { Defaults, HtmlText, RoundBox, Row, Timer } from '../../../common';
import { labelColor, supportUser } from '../../../common/constants';
import {
  BigNotOkIcon,
  BigOkIcon,
  DisputeIcon,
  InfoIcon,
  LikeIcon,
  ToMessageIcon,
} from '../../../common/Icons';
import { DealAction } from '../../../common/panels/controls';
import { TabsComponent } from '../../../common/tabs';
import ActionButton from '../../../controls/ActionButton';
import ChatEx from '../../../controls/Chat';
import { pageFontSize, PageText } from '../../../controls/Page';
import { OrderDetails } from '../OrderDetails';
import { IS_DEFAULT_NOTIFICATION_READ, PURCHASE_STATUSES } from '../Purchase';
import styles from './styles.module.scss';

interface PaymentProcessedProps {
  purchase?: Purchase;
  notifications: INotificationsAggregator;
}

export const PaymentProcessed: FC<PaymentProcessedProps> = ({
  purchase,
  notifications,
}) => {
  const { t } = useTranslation();
  const [voted, setVoted] = useState(false);

  const onUserVote = (method: 'like' | 'dislike') => {
    if (purchase.deal)
      api.deal
        .voteUser(purchase.deal.id, purchase.deal.seller.nickname, method)
        .then(getVoted);
  };

  useEffect(() => {
    purchase.deal?.id && getVoted();
  }, [purchase.deal?.id]);

  const getVoted = () => {
    if (purchase.deal)
      api.deal.get(purchase.deal.id).then((deal) => setVoted(deal.voted));
  };

  const status = useMemo(
    () => ({
      ok: purchase.status === PURCHASE_STATUSES.OK,
      notOk: purchase.status === PURCHASE_STATUSES.NOT_OK,
      waiting:
        purchase.status === PURCHASE_STATUSES.IN_PROCESS &&
        ((purchase.deal?.state === 'paid' && !purchase.deal?.dispute) ||
          purchase.autodeal),
    }),
    [purchase.status, purchase.deal?.state, !purchase.deal?.dispute],
  );

  const backToSiteButton = useMemo(() => {
    if (!purchase?.deal?.dispute && [1, 2, 3].includes(purchase?.status)) {
      return (
        <div className={styles.footer}>
          <DealAction
            className={styles.action}
            caption={'purchase.redirect'}
            onClick={() => {
              window.open(
                urlToOpen(purchase.back_url || purchase.merchant.website || ''),
                purchase?.status === 1 ? '_blank' : '_self',
              );
            }}
            width={'300px'}
          />
        </div>
      );
    }
    return;
  }, [purchase]);

  const isDispute = useMemo(() => !!purchase.deal?.dispute, [purchase.deal?.dispute]);

  const waitingBlock = status.waiting && (
    <div className={classNames(styles.infoContainer, styles.waiting)}>
      <Timer
        time={(purchase.deal?.expire_in ?? purchase.autodeal?.expire_in) * 2}
        isNew
        vertical
        color={'#00B3FB'}
        clockSize={'80px'}
      />
      <div className={styles.title}>{`${t('purchase.deal.status.active')}`}</div>
      <div className={styles.tip}>{`${t('purchase.deal.wait-coins-1')}`}</div>
      {purchase.merchant.allow_waiting_back_url && backToSiteButton}
    </div>
  );
  const okBlock = status.ok && (
    <div className={classNames(styles.infoContainer, styles.ok)}>
      <BigOkIcon />
      <div className={styles.title}>{`${t('purchase.deal.success')}`}</div>
      {backToSiteButton}
      {!voted && !purchase.autodeal && (
        <div className={styles.voteActions}>
          <PageText k={'purchase.deal.vote'} after={':'} color={labelColor} />
          <Row top={'1vh'}>
            <ActionButton
              width={'15vh'}
              onClick={() => onUserVote('like')}
              hoverColor={'rgba(0, 151, 219, 0.2)'}
              swapColors>
              <LikeIcon size={pageFontSize} fill={Defaults.mainColor} />
            </ActionButton>
            <ActionButton
              width={'15vh'}
              swapColors
              buttonColor={Defaults.redColor}
              left={'2vh'}
              hoverColor={'rgba(253, 61, 52, 0.2)'}
              onClick={() => onUserVote('dislike')}>
              <LikeIcon size={pageFontSize} fill={Defaults.redColor} flip />
            </ActionButton>
          </Row>
        </div>
      )}
    </div>
  );
  const notOkBlock = status.notOk && (
    <div className={classNames(styles.infoContainer, styles.notOk)}>
      <BigNotOkIcon />
      <div className={styles.title}>{`${t('purchase.canceled')}`}</div>
      {backToSiteButton}
    </div>
  );

  const disputeBlock = isDispute && (
    <div className={classNames(styles.infoContainer, styles.dispute)}>
      <DisputeIcon />
      <div className={styles.title}>{`${t('purchase.deal.dispute-partner-opened')}`}</div>
      <HtmlText k={'purchase.deal.dispute-partner-opened-2'} textAlign={'center'} />
      <HtmlText
        k={'purchase.deal.dispute-partner-opened-3'}
        textAlign={'center'}
        args={[purchase.deal.id]}
      />
    </div>
  );

  const UnreadMessagesBox = ({ count }: { count?: number }) =>
    !!count && (
      <RoundBox size={'2vh'} fontSize={'1.6vh'} left={'0.4vh'}>
        {count}
      </RoundBox>
    );

  const tabs = {
    info: (
      <div className={styles.tabsInfo}>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.order.merchant')}`}:</div>
          <div className={classNames(styles.value, styles.highlighted)}>
            {purchase.merchant.name}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('sale.deal.sky-id')}`}:</div>
          <div className={classNames(styles.value, styles.highlighted)}>
            {purchase.deal?.id ?? purchase.autodeal?.identificator}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.payment-method')}`}:</div>
          <div className={styles.value}>
            {purchase.deal?.lot.broker.name ?? purchase.autodeal?.broker.name}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.requisite')}`}:</div>
          <div className={styles.value}>
            {purchase.deal?.requisite ?? purchase.requisites}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.give')}`}:</div>
          <div className={styles.value}>
            {purchase.deal?.amount_currency ?? purchase.autodeal?.amount_currency}{' '}
            {purchase.currency.toUpperCase()}
          </div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.get')}`}:</div>
          <div className={styles.value}>
            {purchase.deal?.amount} {purchase.deal?.symbol.toUpperCase()}
          </div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.name}>{`${t('purchase.deal.identifier')}`}:</div>
          <div className={styles.value}>{purchase?.payment_id}</div>
        </div>
        {purchase?.label && (
          <div className={styles.row}>
            <div className={styles.name}>{`${t('purchase.deal.label')}`}:</div>
            <div className={styles.value}>{purchase.label}</div>
          </div>
        )}
      </div>
    ),
    chat: (
      <ChatEx
        user={purchase.deal?.seller.nickname}
        height={'300px'}
        noHeader
        messages={notifications.personalChat.messages}
        onSendMessage={notifications.sendUserMessage}
      />
    ),
    support: (
      <>
        {!notifications.supportChat.isLoading && !notifications.supportChat.isOpened && (
          <div className={styles.openChatContainer}>
            <DealAction
              caption={'purchase.deal.action.open-support-chat'}
              onClick={notifications.startSupportChat}
              width={'300px'}
            />
          </div>
        )}
        {notifications.supportChat.isOpened && (
          <ChatEx
            user={supportUser}
            height={'300px'}
            noHeader
            messages={notifications.supportChat.messages}
            onSendMessage={notifications.sendSupportMessage}
          />
        )}
      </>
    ),
  };

  const needsOrderDetails = useMemo(
    () => voted || purchase.status === PURCHASE_STATUSES.NOT_OK,
    [purchase.deal?.state, purchase.status],
  );

  const isDefaultNotificationRead = JSON.parse(
    sessionStorage.getItem(IS_DEFAULT_NOTIFICATION_READ),
  );

  const unreadMessagesCount =
    (purchase.deal?.state === 'paid' || purchase.deal?.state === 'closed') &&
    !isDefaultNotificationRead
      ? notifications.personalChat.unread.length + 1
      : notifications.personalChat.unread.length;

  return (
    <div className={styles.container}>
      {waitingBlock}
      {okBlock}
      {notOkBlock}
      {disputeBlock}
      {needsOrderDetails ? (
        <div className={styles.details}>
          <OrderDetails purchase={purchase} />
        </div>
      ) : (
        <TabsComponent
          onChange={notifications.setActiveTab}
          className={styles.tabs}
          defaultTab={'info'}
          noGrayscale
          content={{
            ...tabs,
          }}
          tabs={[
            {
              id: 'info',
              icon: <InfoIcon />,
              caption: `purchase.deal.tabs.info`,
            },
            !purchase.autodeal && {
              id: 'chat',
              icon: <ToMessageIcon />,
              caption: 'purchase.deal.tabs.chat',
              component: <UnreadMessagesBox count={unreadMessagesCount} />,
            },
            {
              id: 'support',
              icon: <ToMessageIcon />,
              caption: 'purchase.deal.tabs.support',
              component: (
                <UnreadMessagesBox count={notifications.supportChat.unread.length} />
              ),
            },
          ].filter(Boolean)}
        />
      )}
    </div>
  );
};
