import React from 'react';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { Defaults, px } from '../common';

const IconEx = ({
  src,
  alt,
  top,
  left,
  right,
  size,
  width,
  minSize,
  cursor,
  hint,
  padding,
  borderRadius,
  customStyle,
  onClick,
}: {
  src: string;
  alt?: string;
  top?;
  left?;
  right?;
  size?;
  width?;
  minSize?: number | string;
  cursor?: string;
  hint?: string;
  padding?: number | string;
  borderRadius?: number | string;
  customStyle?: string;
  onClick?: (event) => void;
}) => (
  <Translation>
    {(t) => (
      <Img
        src={src}
        alt={alt ? t(alt) : src}
        top={top}
        left={left}
        right={right}
        size={size}
        width={width}
        minSize={minSize}
        padding={padding}
        borderRadius={borderRadius}
        cursor={cursor}
        title={hint ? t(hint) : undefined}
        customStyle={customStyle}
        onClick={onClick}
      />
    )}
  </Translation>
);

const Img = styled.img`
  height: ${(props) => px(props.size) || `${px(Defaults.imgSize)}`};
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')};
  ${(props) => (props.minSize ? `min-height: ${px(props.minSize)};` : '')}
  cursor: ${(props) => (props.cursor || !!props.onClick ? 'pointer' : '')};
  border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  margin-top: ${(props) => px(props.top)};
  margin-left: ${(props) => px(props.left)};
  margin-right: ${(props) => px(props.right)};
  padding: ${(props) => px(props.padding || 0)};
  ${(props) => (props.customStyle ? props.customStyle : '')}
`;

export default IconEx;

export const ico = (src?: string, size?: number | string) =>
  src ? <IconEx src={src} size={size} /> : undefined;
