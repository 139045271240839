import React, { useMemo, useRef, useState } from 'react';
import { ColorFormat, CountdownCircleTimer } from 'react-countdown-circle-timer';
import QRCode from 'react-qr-code';

import { t } from '../../../../config/i18n';
import { classNames, copyToClipboard } from '../../../../helpers/utils';
import { CPayment } from '../../../../utils/types';
import { Defaults } from '../../../common';
import Txt from '../../../controls/Txt';
import { Card } from '../../../universalComponents/Card';
import { FormItem } from '../../../universalComponents/FormItem';
import { InfoBlock, InfoBlockTypes } from '../../../universalComponents/InfoBlock';
import styles from '../styles.module.css';

interface CryptoPayInProgressProps {
  id: string;
  payment: CPayment;
}

enum PaymentStatusesByNum {
  waiting = 1,
  // pending,
  success,
  overtime,
}

const paymentStatusToInfoBlockType: Record<number, InfoBlockTypes> = {
  0: 'info',
  1: 'info',
  2: 'success',
  3: 'danger',
};

const PAYMENT_TIMEOUT_S = 180 * 60;

export const CryptoPayInProgress = (props: CryptoPayInProgressProps) => {
  const [copied, setCopied] = useState(false);
  const walletRef = useRef(null);

  const onCopy = () => {
    copyToClipboard(props.payment.address).then(() => setCopied(true));
  };

  const countdownTitleProps = useMemo(
    () => ({
      k: `crypto-pay.${PaymentStatusesByNum[props.payment.status]}-title`,
      args:
        PaymentStatusesByNum[props.payment.status] === 'waiting'
          ? [180]
          : [props.payment.amount, props.payment.symbol.toUpperCase()],
    }),
    [props.payment.status],
  );

  const generateQrString = {
    btc: () => `bitcoin:${props.payment.address}?amount=${props.payment.amount}`,
    eth: () => `ethereum:${props.payment.address}?amount=${props.payment.amount}`,
  };

  const getInitialRemainingTime = () => {
    return (
      PAYMENT_TIMEOUT_S -
      (new Date().getTime() - new Date(props.payment.created_at).getTime()) / 1000
    );
  };

  return (
    <Card className={styles.card}>
      <div className={styles.cryptoInProcess}>
        <FormItem
          label={
            <Txt
              k={`crypto-pay.copy-address${
                props.payment.symbol !== 'usdt' ? '-or-qr' : ''
              }`}
            />
          }>
          <FormItem
            className={styles.copyContainer}
            label={
              <span className={styles.walletLabel}>
                <span className={styles.paymentInfo}>
                  {props.payment.amount_received > 0 && (
                    <span>
                      <Txt
                        k={'crypto-pay.paid-amount'}
                        args={[
                          `${
                            props.payment.amount_received
                          } ${props.payment.symbol.toUpperCase()}`,
                        ]}
                      />
                    </span>
                  )}
                  {props.payment.amount_left > 0 && (
                    <span>
                      <Txt
                        k={'crypto-pay.wallet-link'}
                        args={[
                          `${
                            props.payment.amount_left
                          } ${props.payment.symbol.toUpperCase()}`,
                        ]}
                      />
                    </span>
                  )}
                </span>
                <span className={classNames(styles.copied, copied && styles.displayed)}>
                  {<Txt k={'crypto-pay.copied'} />}
                </span>
              </span>
            }>
            <span onClick={onCopy} ref={walletRef} className={styles.walletAddress}>
              {props.payment.address}
            </span>
          </FormItem>
        </FormItem>
        {props.payment.symbol !== 'usdt' && (
          <div className={styles.qrContainer}>
            <QRCode
              className={styles.qr}
              size={Math.floor(window.innerHeight * 0.18)}
              value={generateQrString[props.payment.symbol]()}
            />
          </div>
        )}
        <InfoBlock
          type={paymentStatusToInfoBlockType[props.payment.status]}
          title={<Txt {...countdownTitleProps} />}
          customIcon={
            props.payment.status === 1 ? (
              <CountdownCircleTimer
                isPlaying
                duration={PAYMENT_TIMEOUT_S}
                initialRemainingTime={getInitialRemainingTime()}
                size={72}
                strokeWidth={5}
                colors={Defaults.mainColor as ColorFormat}>
                {({ remainingTime }) => (
                  <span
                    className={classNames(
                      styles.timeLeft,
                      styles[paymentStatusToInfoBlockType[props.payment.status]],
                    )}>
                    {remainingTime > 599
                      ? Math.floor(remainingTime / 60)
                      : `${Math.floor(remainingTime / 60)}:${remainingTime % 60}`}
                  </span>
                )}
              </CountdownCircleTimer>
            ) : null
          }>
          {<Txt k={`crypto-pay.${PaymentStatusesByNum[props.payment.status]}-tip`} />}
        </InfoBlock>
      </div>
    </Card>
  );
};
